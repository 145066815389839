import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api'

export default function DataEntry() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
    const [instituteName, setInstituteName] = useState('');
    const [currentInstructorlist, setCurrentInstructorlist] = useState([]);
    const [channels, setChannels] = useState([]);
    const navigate = useNavigate();
    const [instituteSuccessMessage, setInstituteSuccessMessage] = useState('');
    const [instructorSuccessMessage, setInstructorSuccessMessage] = useState('');
    const [coursesSuccessMessage, setCoursesSuccessMessage] = useState('');

    const [insertedInstituteId, setInsertedInstituteId] = useState(null);
    const [instructors, setInstructors] = useState(['']);
    const [courses, setCourses] = useState([{ courseName: '', courseUrl: '', channelName: '', instructorName: '' }]);


    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin:isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                
                if (!response.data.isValid){
                    // console.log(response.data.isValid)
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid)
                    navigate('/SignIn');
                    // window.location.reload();
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);

    


    useEffect(() => {
        axios.put(API_BASE_URL + '/api/insertInstituteName', { instituteName }, {
            headers: {
                'x-api-key': hashedApiKey
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setInstituteSuccessMessage('Successfully inserted');
                    setInsertedInstituteId(response.data.institute_id)
                }
            })
            .catch(error => {
                setInstituteSuccessMessage('Error inserting institute name');
                console.error(error);
            });

    }, [instituteName]);


    const handleMoreButtonClick = () => {
        setInstructors([...instructors, '']);
    };

    const handleCourseMoreButtonClick = () => {
        setCourses([...courses, { courseName: '', courseUrl: '', channelName: '', instructorName: '' }]);
    };

    const handleInstructorInputChange = (index, value) => {
        const updatedInstructors = [...instructors];
        updatedInstructors[index] = value;
        setInstructors(updatedInstructors);
    };

    const handleCourseInputChange = (index, field, value) => {
        const updatedCourses = [...courses];
        updatedCourses[index][field] = value;
        setCourses(updatedCourses);
    };

    const handleInstituteSubmit = (e) => {
        e.preventDefault();
        setInstituteName(e.target.elements.instituteName.value);
    };

    const handleInstructorSubmit = (e) => {
        e.preventDefault();
        axios.put(API_BASE_URL + '/api/insertInstructors', { instructors, insertedInstituteId }, {
            headers: {
                'x-api-key': hashedApiKey
            }
        })
            .then((response) => {
                setChannels(response.data.channels)
                setCurrentInstructorlist(response.data.instructorsDetail)
                setInstructorSuccessMessage('Successfully inserted');
            })
            .catch((error) => {
                setInstructorSuccessMessage('Error inserting institute name');
                console.error(error);
            });
    }

    const handleCoursesSubmit = (e) => {
        e.preventDefault();
        axios.put(API_BASE_URL + '/api/insertCoursesDetails', { courses, insertedInstituteId }, {
            headers: {
                'x-api-key': hashedApiKey
            }
        })
            .then((response) => {
                setCoursesSuccessMessage('Successfully inserted')
            })
            .catch((error) => {
                setCoursesSuccessMessage('Error inserting institute name')
                console.error(error);
            });
    }


    return (
        <>
            <div className='mt-5'>
                <div class="container" style={{ border: '2px solid black', padding: '20px', borderRadius: '5px' }}>
                    <h3 className='mb-3'>Institute Entry</h3>
                    <form onSubmit={handleInstituteSubmit}>
                        <div class="form-group">
                            <label htmlFor="instituteName"><strong>Institute Name:</strong></label>
                            <input type="text" class="form-control col-md-6" id="instituteName" />
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                        {instituteSuccessMessage && <span className="text-success ml-2">{instituteSuccessMessage}</span>}
                        {instituteSuccessMessage && <span className="text-primary ml-2">Institute ID: {insertedInstituteId}</span>}
                    </form>
                </div>
            </div>
            <div className="mt-5">
                <div className="container" style={{ border: '2px solid black', padding: '20px', borderRadius: '5px' }}>
                    <h3 className="mb-3">Instructor Entry</h3>
                    <form onSubmit={handleInstructorSubmit}>
                        {instructors.map((instructor, index) => (
                            <div className="form-group" key={index}>
                                <label htmlFor={`inputField${index}`}><strong>Instructor Name:</strong></label>
                                <input
                                    type="text"
                                    className="form-control col-md-6"
                                    id={`inputField${index}`}
                                    value={instructor}
                                    onChange={(e) => handleInstructorInputChange(index, e.target.value)}
                                />
                            </div>
                        ))}
                        <button type="button" className="btn btn-secondary mr-2" onClick={handleMoreButtonClick}>More</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                        {instructorSuccessMessage && <span className="text-success ml-2">{instructorSuccessMessage}</span>}
                    </form>
                </div>
            </div>
            <div className="mt-5">
                <div className="container" style={{ border: '2px solid black', padding: '20px', borderRadius: '5px' }}>
                    <h3 className="mb-3">Courses Detail Entry</h3>
                    <form onSubmit={handleCoursesSubmit}>
                        {courses.map((course, index) => (
                            <div key={index} className="row form-group" style={{ border: '2px dashed black', margin: '20px', padding: '20px', borderRadius: '5px' }}>
                                <div className='col-md-6'>
                                    <label htmlFor={`courseName${index}`}><strong>Course Name:</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`courseName${index}`}
                                        value={course.courseName}
                                        onChange={(e) => handleCourseInputChange(index, 'courseName', e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor={`courseUrl${index}`}><strong>Course Url:</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`courseUrl${index}`}
                                        value={course.courseUrl}
                                        onChange={(e) => handleCourseInputChange(index, 'courseUrl', e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor={`channelName${index}`}><strong>Channel Name:</strong></label>
                                    <select
                                        className="form-control"
                                        id={`channelName${index}`}
                                        value={course.channelName}
                                        onChange={(e) => handleCourseInputChange(index, 'channelName', e.target.value)}
                                    >
                                        <option value="">Select Channel Name</option>
                                        {channels.map((channel) => (
                                            <option value={channel.channelid} key={channel.channelid}>{channel.channelname}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-md-6'>
                                    <label htmlFor={`instructorName${index}`}><strong>Instructor Name:</strong></label>
                                    <select
                                        className="form-control"
                                        id={`instructorName${index}`}
                                        value={course.instructorName}
                                        onChange={(e) => handleCourseInputChange(index, 'instructorName', e.target.value)}
                                    >
                                        <option value="">Select Instructor Name</option>
                                        {currentInstructorlist.map((instructor) => (
                                            <option value={instructor.id} key={instructor.id}>{instructor.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ))}
                        <button type="button" className="btn btn-secondary mr-2" onClick={handleCourseMoreButtonClick}>More</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                        {coursesSuccessMessage && <span className="text-success ml-2">{coursesSuccessMessage}</span>}
                    </form>
                </div>
            </div>
        </>
    )
}
