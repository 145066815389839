import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';


import ReactBarChart from '../ReactBarChart'
import ReactStackedBarChart from '../ReactStackedBarChart'
import Header from './InstructorHeaders/Header';
import style from './InstructorContent.module.css'
import ReactDonetChart from '../ReactDonetChart';
import ReactPieChart from '../ReactPieChart';
import ReactWordCloud from '../ReactWordCloud';
import DashboardReviewsReport from '../DashboardReviewsReports/DashboardReviewsReport';
import ReactCompatitorChart from '../ReactCompatitorChart';
import ReactMultiLineChart from '../ReactMultiLineChart'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';
// import { url } from '../../../';
// console.log(process.env.REACT_APP_API_URL)


export default function InstructorContent({ memberGroup, id, setPage, setInstructorId, setTopReviews, setTopRecommendations, setCourseID, setFilter }) {
    const [instructorSummaryByFactors, setInstructorSummaryByFactors] = useState([])
    const [instructorSummary, setInstructorSummary] = useState([])
    const [instructorFactorsDetail, setInstructorFactorsDetail] = useState({})
    const [instructorStarCount, setInstructorStarCount] = useState([])
    const [donutTotal, setDonutTotal] = useState([])
    const [donutPositive, setDonutPositive] = useState([])
    const [donutName, setDonutName] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [instructorCoursesDetail, setInstructorCoursesDetail] = useState([])
    const [instructorTopReviews, setInstructorTopReviews] = useState([])
    const [instructorTopRecommendations, setInstructorTopRecommendations] = useState([])
    const [instructorWordCloud, setInstructorWordCloud] = useState([])
    const [lineChartData, setLineChartData] = useState({})
    const [instructorSummaryInsights, setInstructorSummaryInsights] = useState([])
    const [instructorData, setInstructorData] = useState({
        InstructorOverall: 0,
        InstructorContent: 0,
        InstructorEngagement: 0,
        InstructorPractice: 0,
        InstructorCareerBenefit: 0
    });

    const [competitorData, setCompetitorData] = useState({
        CompetitorOverall: 0,
        CompetitorContent: 0,
        CompetitorEngagement: 0,
        CompetitorPractice: 0,
        CompetitorCareerBenefit: 0
    });

    const [instructorInsights, setInstructorInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        let compatitorPositive = 0
        let instructorPositive = 0
        Axios.get(API_BASE_URL + '/api/getInstructorSummary', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            let starCount = response.data.map(record => record.Star_Count)[0]
            starCount = starCount.split(/[,\s]/).map(Number)
            setInstructorStarCount(starCount)
            setInstructorSummary(response.data)
            const InstructorOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100)
            const InstructorOverallTotal = response.data[0].Total
            instructorPositive = response.data[0].Positive
            setInstructorData(prevState => ({ ...prevState, InstructorOverall, InstructorOverallTotal }));
        })
        Axios.get(API_BASE_URL + '/api/getInstructorName', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const InstructorName = response.data[0].Instructor_Name
            setInstructorData(prevState => ({ ...prevState, InstructorName }));
        })

        // ------------------------compatitor data ----------------------

        Axios.get(API_BASE_URL + '/api/getCompetitorName', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const CompetitorName = response.data[0].Instructor_Name
            setCompetitorData(prevState => ({ ...prevState, CompetitorName }));
        })
        Axios.get(API_BASE_URL + '/api/getCompetitorSummary', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorOverall = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorOverallTotal = response.data[0].Total
                compatitorPositive = response.data[0].Positive
                setCompetitorData(prevState => ({ ...prevState, CompetitorOverall, CompetitorOverallTotal }));


                // Percentage difference = ((80 - 100) / 100) * 100%
                let percentage = ((instructorPositive - compatitorPositive) / compatitorPositive) * 100
                let insight_3_result = percentage.toFixed(1)
                setInstructorInsights(prevState => ({ ...prevState, insight_3_result }))



            });
        Axios.get(API_BASE_URL + '/api/getCompetitorSummaryByFactors', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const CompetitorContent = Math.round((response.data[0].Positive / response.data[0].Total) * 100);
                const CompetitorContentTotal = response.data[0].Total
                const CompetitorEngagement = Math.round((response.data[1].Positive / response.data[1].Total) * 100);
                const CompetitorEngagementTotal = response.data[1].Total
                const CompetitorPractice = Math.round((response.data[2].Positive / response.data[2].Total) * 100);
                const CompetitorPracticeTotal = response.data[2].Total
                const CompetitorCareerBenefit = Math.round((response.data[3].Positive / response.data[3].Total) * 100);
                const CompetitorCareerBenefitTotal = response.data[3].Total
                setCompetitorData(prevState => ({ ...prevState, CompetitorContent, CompetitorContentTotal, CompetitorEngagement, CompetitorEngagementTotal, CompetitorPractice, CompetitorPracticeTotal, CompetitorCareerBenefit, CompetitorCareerBenefitTotal }));
            });
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorSummaryByFactors', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const factorsdetail = {
                'positive': response.data.map(record => record.Positive),
                'negative': response.data.map(record => record.Negative),
                'neutral': response.data.map(record => record.Neutral)
            }
            const InstructorContent = Math.round((response.data[0].Positive / response.data[0].Total) * 100)
            const InstructorEngagement = Math.round((response.data[1].Positive / response.data[1].Total) * 100)
            const InstructorPractice = Math.round((response.data[2].Positive / response.data[2].Total) * 100)
            const InstructorCareerBenefit = Math.round((response.data[3].Positive / response.data[3].Total) * 100)
            const InstructorCareerBenefitTotal = response.data[3].Total
            const InstructorPracticeTotal = response.data[2].Total
            const InstructorEngagementTotal = response.data[1].Total
            const InstructorContentTotal = response.data[0].Total

            setInstructorData(prevState => ({ ...prevState, InstructorContent, InstructorContentTotal, InstructorEngagement, InstructorEngagementTotal, InstructorPractice, InstructorPracticeTotal, InstructorCareerBenefit, InstructorCareerBenefitTotal }));

            setDonutName(response.data.map(record => record.Factor_Name).slice(0, -1))
            setDonutPositive(response.data.map(record => record.Positive).slice(0, -1))
            setDonutTotal(response.data.map(record => record.Total).slice(0, -1))
            setInstructorFactorsDetail(factorsdetail)
            setInstructorSummaryByFactors(response.data)

        })

    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorLineChart', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const data = response.data;
            const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            const formattedDates = reviewMonths.map(dateStr => {
                const [year, month] = dateStr.split('-');
                const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                return `${monthName} ${year}`;
            });
            const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);

            const LinechartData = {
                formattedDates,
                positiveReviews,
                negativeReviews,
                neutralReviews
            };
            setLineChartData(LinechartData)    


            // Format dates, sort by date, and keep the last 12 months
            // const formattedData = data.map(item => {
            //     const [day, month, year] = item.review_month.split('/');
            //     // console.log([day, month, year])
            //     return {
            //         // review_month: new Date(`${year}-${month}-${day}`),//.toLocaleString('default', { month: 'short', year: 'numeric' }),
            //         review_month: `${month}/${year}`,
            //         positive_reviews: item.positive_reviews,
            //         negative_reviews: item.negative_reviews,
            //         neutral_reviews: item.neutral_reviews
            //     };
            // }).sort((a, b) => {
            //     const [monthA, yearA] = a.review_month.split('/');
            //     const [monthB, yearB] = b.review_month.split('/');
            //     return new Date(`${yearA}-${monthA}`) - new Date(`${yearB}-${monthB}`);
            //   }).slice(-12);
            // console.log(formattedData)
            // setLineChartData(formattedData)



            // Get the current year and the previous year
            // const currentYear = new Date().getFullYear();
            // const lastYear = currentYear - 1;

            // // Filter the data to get only the entries for the last complete year and some current months
            // const filteredData = data.filter(item => {
            //     const year = parseInt(item.review_month.split("-")[0]);
            //     const month = parseInt(item.review_month.split("-")[1]);
            //     return year === lastYear || (year === currentYear && month <= 3);
            // });
            // const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            // const formattedDates = reviewMonths.map(dateStr => {
            //     const [year, month] = dateStr.split('-');
            //     const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
            //     return `${monthName} ${year}`;
            // });
            // const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            // const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            // const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);
            // const LinechartData = {
            //     formattedDates,
            //     positiveReviews,
            //     negativeReviews,
            //     neutralReviews
            // };
            // setLineChartData(LinechartData)
            // Sort the data by date
            // data.sort((a, b) => new Date(a.review_month) - new Date(b.review_month));

            // const reviewMonths = data.map(item => item.review_month).slice(-12); // Get the last 12 months
            // const formattedDates = reviewMonths.map(dateStr => {
            //     const [day, month, year] = dateStr.split('-'); // Splitting the date using "/"
            //     const monthName = new Date(`${year}-${month}-${day}`).toLocaleString('default', { month: 'short' });
            //     return `${monthName} ${year}`;
            // });
            // const positiveReviews = data.map(item => item.positive_reviews).slice(-12); // Get the last 12 months' data
            // const negativeReviews = data.map(item => item.negative_reviews).slice(-12); // Get the last 12 months' data
            // const neutralReviews = data.map(item => item.neutral_reviews).slice(-12); // Get the last 12 months' data

            // const LinechartData = {
            //     formattedDates,
            //     positiveReviews,
            //     negativeReviews,
            //     neutralReviews
            // };
            // const hardcodedData = {
            //     formattedDates: [
            //         'Jul 2023', 'Aug 2023', 'Sep 2023', 'Oct 2023', 'Nov 2023', 'Dec 2023', 'Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024'
            //     ],
            //     negativeReviews: [0, 4, 1, 5, 1, 3, 0, 3, 1, 4, 3, 0],
            //     neutralReviews: [7, 6, 4, 6, 7, 9, 7, 6, 7, 9, 7, 6],
            //     positiveReviews: [77, 67, 58, 77, 67, 87, 96, 87, 77, 67, 87, 77]
            // };

            // Manually set the data using useState
            // setLineChartData(hardcodedData);

            // console.log(LinechartData)
            // setLineChartData(LinechartData);
            // const _6_month = LinechartData.positiveReviews.slice(-6)


            // // -------------------- insight no 1 ----------------------
            // let previous_3_months_sum = LinechartData.positiveReviews.slice(-6)[0] + LinechartData.positiveReviews.slice(-6)[1] + LinechartData.positiveReviews.slice(-6)[2]
            // let last_3_months_sum = LinechartData.positiveReviews.slice(-6)[3] + LinechartData.positiveReviews.slice(-6)[4] + LinechartData.positiveReviews.slice(-6)[5]
            // let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
            // let avg = (last_3_months_sum + previous_3_months_sum) / 2;
            // let percentage = (absoluteDiff / avg) * 100;
            // let insight_1_result = percentage.toFixed(1);
            // let insight_1_grade = false

            // if (last_3_months_sum > previous_3_months_sum) {
            //     insight_1_grade = true
            // } else {
            //     insight_1_grade = false
            // }
            // // -------------------- insight no 2 ----------------------
            // const previous_1_months_sum = LinechartData.positiveReviews.slice(-2)[0] + LinechartData.negativeReviews.slice(-2)[0] + LinechartData.neutralReviews.slice(-2)[0]
            // const last_1_months_sum = LinechartData.positiveReviews.slice(-2)[1] + LinechartData.negativeReviews.slice(-2)[1] + LinechartData.neutralReviews.slice(-2)[1]
            // absoluteDiff = Math.abs(last_1_months_sum - previous_1_months_sum);
            // avg = (last_1_months_sum + previous_1_months_sum) / 2;
            // percentage = (absoluteDiff / avg) * 100;
            // let insight_2_result = percentage.toFixed(1);
            // let insight_2_grade = false
            // if (last_1_months_sum > previous_1_months_sum) {
            //     insight_2_grade = true
            // } else {
            //     insight_2_grade = false
            // }

            // setInstructorInsights(prevState => ({ ...prevState, insight_1_result, insight_1_grade, insight_2_result, insight_2_grade }))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getHeaderData', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setHeaderData(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorWordCloud', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstructorWordCloud(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorInsights', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            // console.log(JSON.parse(response.data[0].key_themes))
            setInstructorSummaryInsights(JSON.parse(response.data[0].key_themes))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorCoursesDetail', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstructorCoursesDetail(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorTopReviews', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstructorTopReviews(response.data)
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorTopRecommendations', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstructorTopRecommendations(response.data)
        })
    }, [])

    // $(document).ready(function () {
    //     $('#example').DataTable();
    // });
    // $(document).ready(function() {
    //     $('#example').DataTable();
    // } );
    useEffect(() => {
        if (instructorCoursesDetail.length > 0) {
            $('#example').DataTable()
        }
    }, [instructorCoursesDetail]);
    // useEffect(() => {
    //     if (instructorCoursesDetail.length > 0) {
    //       const table = document.querySelector('#example');
    //       if (table) {
    //         table.DataTable();
    //       }
    //     }
    //   }, [instructorCoursesDetail]);


    let counter = 0;
    return (
        <main class={style.content} >

            <div class="container p-0 mt-3">
                <h1 class="mb-3"><strong>{instructorData.InstructorName}</strong></h1>
                <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex flex-column flex-lg-row">
                        <div className='col-md-12 col-lg-7 col-xl-8 mt-3 order-md-1'>
                            {headerData.map((data, index) => (
                                <p key={index} className={`${style.text_muted} mb-3`}>
                                    {instructorData.InstructorName} has an overall rating of {data.TTC_Score.toFixed(1)} out of 5.0 based on {data.Total} total reviews analyzed.
                                </p>
                            ))}
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                {/* <li class={`${style.text_muted}`}>
                                    Over the last 3 months, the number of positive reviews you've received has
                                    {instructorInsights.insight_1_grade ? ` increased by ${instructorInsights.insight_1_result}% ` : ` decreased by ${instructorInsights.insight_1_result}% `}
                                    compared to the previous period.
                                </li>
                                <li class={`${style.text_muted}`}>You currently have {instructorInsights.insight_3_result >= 0 ? `${instructorInsights.insight_3_result}% more` : ` ${-instructorInsights.insight_3_result}% fewer`} positive reviews than your top competitor.</li>
                                <li class={`${style.text_muted}`}>In the last month, the number of reviews you received
                                    {instructorInsights.insight_2_grade ? ` increased by ${instructorInsights.insight_2_result}% ` : ` decreased by ${instructorInsights.insight_2_result}% `}
                                    compared to the previous month.</li> */}
                                {/* <li class={`${style.text_muted}`}>
                                    Chris's course on 'Computer Programming for Everyone' has received 808 positive reviews, reflecting its high demand and impact.
                                </li>
                                <li class={`${style.text_muted}`}>
                                    'Essential Skills for Your Career Development' has achieved a notable milestone, experiencing a 5% increase in total reviews compared to month of June 2024.
                                </li>
                                <li class={`${style.text_muted}`}>
                                    Despite the overall decline, Chris has maintained a steady flow of positive reviews, highlighting his consistent teaching excellence.
                                </li> */}
                                {instructorSummaryInsights.map((insight, index) => (
                                    <li key={index} className={`${style.text_muted}`}>
                                        {insight}
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <div className={`col-md-12 col-lg-5 col-xl-4 mt-2 order-md-2`}>
                            <ReactPieChart StarCount={instructorStarCount} />
                        </div>
                    </div>
                </div>

                {/* <div className="row mb-3 mt-3">
                    <div className="col-12 col-md-6 col-xl-4 mb-4">
                        <div className="row">
                            {headerData.map((data) => (
                                <>
                                    <div className="col-6 mb-4">
                                        <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                    </div>
                                    <div className="col-6 mb-4">
                                        <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                    </div>
                                    <div className="col-6 mb-4">
                                        <Header name={'Positive Reviews'} value={`${((data.Positive / data.Total) * 100).toFixed(0)}%`} icon={'fa-regular fa-thumbs-up'} />
                                    </div>
                                    <div className="col-6 mb-4">
                                        <Header name={'Courses Analyzed'} value={data.total} icon={'fa-solid fa-check'} />
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-8">
                        <div class={`${style.card} w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`} style={{ display: 'inline-block' }}>Overall Score (1-5)</h5>
                                <p className='ml-1' style={{ display: 'inline-block', padding: 0, margin: 0, }}>based on sentiment analysis</p>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={instructorStarCount} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={`row mb-3 mt-3 ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''} `} >
                    <div className='col-lg-12 col-xl-6'>
                        <div className='row d-flex'>
                            {headerData.map((data) => (
                                <>
                                    <div className='col-6 mb-4'>
                                        <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Positive Reviews'} value={((data.Positive / data.Total) * 100).toFixed(0) + '%'} icon={'fa-regular fa-thumbs-up'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Courses Analyzed'} value={data.total} icon={'fa-solid fa-check'} />
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`} style={{ display: 'inline-block' }}>Overall Score (1-5)</h5><p className='ml-1' style={{ display: 'inline-block', padding: 0, margin: 0, }}>based on sentiment analysis</p>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={instructorStarCount} />
                            </div>
                        </div>
                    </div>
                </div>


                {/* bar chart */}
                {/* <div class="row">
                    <div class="col-12 col-md-6 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title} mb-0`}>Overall Score (1-5)</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={instructorStarCount} />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title} mb-0`}>Breakdown Of Sentiments</h5>
                            </div>
                            <div style={{ height: '320px' }} class={`${style.card_body} d-flex justify-content-center align-items-center`}>
                                <ReactPieChart StarCount={instructorStarCount} />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* line chart */}
                <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-3 mb-3">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Track the Pulse: Sentiment Trends Over Time</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Stay ahead of the curve and monitor sentiment trends over time with our intuitive sentiment analysis graph.</p>
                                <ReactMultiLineChart lineChartData={lineChartData} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* competitor */}
                {/* <div class={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div class="col-12 d-flex">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Competitor Comparison Chart</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Compare your positive sentiment with your top competitor to gain insight into areas of improvement and stay ahead of the competition.</p>
                                <ReactCompatitorChart
                                    id={id}
                                    instructorData={instructorData}
                                    competitorData={competitorData}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* recommandations */}
                {/* <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div class="col-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Top recommandations: Insights to Elevate Your Teaching</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Discover the top recommendations and areas for improvement extracted from student feedback to enhance your teaching methods and improve overall satisfaction.</p>
                                <div class="table-responsive">
                                    <table class={`${style.table} table table-hover my-0`}>
                                        <thead>
                                            <tr>
                                                <th class="text-center d-none d-sm-none d-md-table-cell">#</th>
                                                <th class="col-1 col-sm-1 d-sm-table-cell text-center">Review By</th>
                                                <th class="col-11 col-sm-10 col-md-7 text-center">Review</th>
                                                <th class="d-none d-sm-none d-md-table-cell text-center">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {instructorTopRecommendations.map((data, index) => (
                                                <tr>
                                                    <td class="text-center d-none d-sm-none d-md-table-cell">{index + 1}</td>
                                                    <td class="col-1 col-sm-1 col-md-3 d-sm-table-cell text-center">{data.Display_Name}</td>
                                                    <td class="col-11 col-sm-10 col-md-7">{data.Course_Review}</td>
                                                    <td class="d-none d-sm-none d-md-table-cell text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='text-center mb-3'>
                                    <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setInstructorId(id); setFilter({ course: '*', factor: 'Is_Recommendations_Review', score: '*' }) }}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row mt-3">
                    <div class="col-12 col-md-6 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title} mb-0`}>Analysis By Effectiveness Factors</h5>
                            </div>
                            <div class={`${style.card_body} ${style.chart_size} px-4`}>
                                <ReactStackedBarChart FactorsDetail={instructorFactorsDetail} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-6 d-flex">
                        <div className={style.card}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title} mb-0`}>Learner Feedback</h5>
                            </div>
                            <div class={`row mb-0 mt-3 ${style.card_body} px-4`}>
                                {donutTotal.map((data, index) => (
                                    <>
                                        <ReactDonetChart total={data} positive={donutPositive[index]} name={donutName[index]} index={index} />
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header} align-center`}>
                                <h4 class={`${style.card_title}  mb-0`}>Top Reviews</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th class="text-center col-1">#</th>
                                        <th class="d-none d-xl-table-cell col-2 text-center">Review By</th>
                                        <th class="d-none d-xl-table-cell col-7 text-center">Review</th>
                                        <th class="d-none d-xl-table-cell col-2 text-center">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div className="d-none">
                                        {counter = 0}
                                    </div>
                                    {
                                        instructorTopReviews.slice(0, 5).map((data) => (
                                            <tr>
                                                <td class='text-center col-1'>{++counter}</td>
                                                <td class="d-none d-xl-table-cell col-2 text-center">{data.Display_Name}</td>
                                                <td class="d-none d-xl-table-cell col-8">{data.Course_Review}</td>
                                                <td class="d-none d-xl-table-cell col-1 text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='text-center'>
                                <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setInstructorId(id); setTopReviews("5") }}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* courses */}
                <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header} align-center`}>
                                <h4 class={`${style.card_title}  mb-0`}>Chris Courses</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>List of all courses taught by Chris Savages , with links to access individual course dashboard.</p>
                                <div class="table-responsive">
                                    <table id='example' className={`${style.table} table table-hover my-0`}>
                                        <thead>
                                            <tr>
                                                {/* <th className="text-center d-none d-sm-none d-lg-table-cell">#</th> */}
                                                <th className="d-sm-table-cell col-7 col-sm-9 col-md-7 text-center">Course Name</th>
                                                <th className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">Total Reviews</th>
                                                <th className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">Total Positive</th>
                                                <th className="col-2 col-sm-1 d-md-table-cell text-center">Rating</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {instructorCoursesDetail.map((data, index) => (
                                                <tr key={index}>
                                                    {/* <td className='text-center d-none d-sm-none d-lg-table-cell'>{index + 1}</td> */}
                                                    <td className="d-sm-table-cell col-7 col-sm-9 col-md-7"><Link onClick={() => { setPage('courses'); setCourseID(data.Course_ID) }}>{data.Course_Name}</Link></td>
                                                    <td className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">{data.Total}</td>
                                                    <td className="col-2 col-sm-1 d-none d-sm-none d-md-table-cell text-center">{data.Positive}</td>
                                                    <td className="col-2 col-sm-1 d-md-table-cell text-center">{data.TTC_Score.toFixed(1)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <div class="text-center">
                            <p class={`${style.text_muted}`}>
                                Follow Us on
                                <a href="https://www.linkedin.com/company/senteez/" target="_blank" class="mx-1">LinkedIn</a> |
                                <a href="https://www.instagram.com/senteezanalysis/" target="_blank" class="mx-1">Instagram</a>
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div class="row">
                    <div class="col-12 d-flex">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title} mb-0`}>Word Cloud</h5>
                            </div>
                            <div className="card-body">
                                <ReactWordCloud instructorWordCloud={instructorWordCloud} />
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* <div class="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header} align-center`}>
                                <h4 class={`${style.card_title}  mb-0`}>Instructor Summary</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="d-none d-xl-table-cell">Total Count</th>
                                        <th class="d-none d-xl-table-cell">Total Positive</th>
                                        <th>Total Neutral</th>
                                        <th>Total Negative</th>
                                        <th class="d-none d-md-table-cell">TTC Score</th>
                                        <th class="d-none d-md-table-cell">Star Count(1-5)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        instructorSummary.map((data) => (
                                            <tr>
                                                <td>{data.Instructor_ID}</td>
                                                <td class="d-none d-xl-table-cell">{data.Total}</td>
                                                <td class="d-none d-xl-table-cell">{Math.round(data.Positive + (data.Neutral / 2))}</td>
                                                <td class="d-none d-xl-table-cell">{0}</td>
                                                <td class="d-none d-xl-table-cell">{Math.round(data.Negative + (data.Neutral / 2))}</td>
                                                <td class="d-none d-xl-table-cell">{Number((((data.Positive + (data.Neutral) / 2) / data.Total) * 5).toFixed(1))}</td>
                                                <td class="d-none d-xl-table-cell">{data.Star_Count}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill`}>
                            <div class={`${style.card_header} align-center`}>
                                <h4 class={`${style.card_title}  mb-0`}>Instructor Summary By Factors</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="d-none d-xl-table-cell">Factors</th>
                                        <th class="d-none d-xl-table-cell">Total Count</th>
                                        <th class="d-none d-xl-table-cell">Total Positive</th>
                                        <th class="d-none d-xl-table-cell">Total Neutral</th>
                                        <th class="d-none d-xl-table-cell">Total Negative</th>
                                        <th class="d-none d-md-table-cell">TTC Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        instructorSummaryByFactors.map((data) => (
                                            <tr>
                                                <td>{data.Instructor_ID}</td>
                                                <td class="d-none d-xl-table-cell">{data.Factor_Name}</td>
                                                <td class="d-none d-xl-table-cell">{data.Total}</td>
                                                <td class="d-none d-xl-table-cell">{data.Positive}</td>
                                                <td class="d-none d-xl-table-cell">{data.Neutral}</td>
                                                <td class="d-none d-xl-table-cell">{data.Negative}</td>
                                                <td class="d-none d-xl-table-cell">{data.TTC_Score}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}


            </div>
        </main>
    )
}
