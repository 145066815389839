import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Axios from 'axios'

import ReactBarChart from '../ReactBarChart'
import Header from '../DashboardInstructorContent/InstructorHeaders/Header';
import ReactStackedBarChart from '../ReactStackedBarChart'
import ReactPieChart from '../ReactPieChart';
import ReactDonetChart from '../ReactDonetChart';
import ReactMultiLineChart from '../ReactMultiLineChart'
import ReactHorizontalBarChart from '../ReactHorizontalBarChart';

import style from '../DashboardInstructorContent/InstructorContent.module.css'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

export default function CourseContent({ memberGroup, courseID, setPage, setFilter }) {
    const [courseSummaryByFactors, setCourseSummaryByFactors] = useState([])
    const [courseSummary, setCourseSummary] = useState([])
    const [courseFactorsDetail, setCourseFactorsDetail] = useState({})
    const [courseStarCount, setCourseStarCount] = useState([])
    const [courseHeaderData, setCourseHeaderData] = useState([])
    const [donutTotal, setDonutTotal] = useState([])
    const [donutPositive, setDonutPositive] = useState([])
    const [donutName, setDonutName] = useState([])
    const [courseTopReviews, setCourseTopReviews] = useState([])
    const [courseTopRecommendations, setCourseTopRecommendations] = useState([])
    const [courseName, setCourseName] = useState([])
    const [lineChartData, setLineChartData] = useState({})
    const [courseSummaryInsight, setCourseSummaryInsight] = useState([])
    const [courseInsights, setCourseInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });
    const [courseRecurringThemes, setCourseRecurringThemes] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], counts: [] });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseSummaryByFactors',
            {
                params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey }
            }).then((response) => {
                const factorsdetail = {
                    'positive': response.data.map(record => record.Positive),
                    'negative': response.data.map(record => record.Negative),
                    'neutral': response.data.map(record => record.Neutral)
                }

                setDonutName(response.data.map(record => record.Factor_Name).slice(0, -1))
                setDonutPositive(response.data.map(record => record.Positive).slice(0, -1))
                setDonutTotal(response.data.map(record => record.Total).slice(0, -1))
                setCourseFactorsDetail(factorsdetail)
                setCourseSummaryByFactors(response.data)
            })
    }, [courseID])



    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseSummary', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            let starCount = response.data.map(record => record.Star_Count)[0]
            starCount = starCount.split(/[,\s]/).map(Number)
            setCourseStarCount(starCount)
            setCourseSummary(response.data)
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseName', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseName(response.data)
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseheaderData', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseHeaderData(response.data)
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseRecurringThemes', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            console.log(response.data)
            // setCourseRecurringThemes(response.data.sort((a, b) => b.Total - a.Total))
            const sortedData = response.data.sort((a, b) => b.Total - a.Total);
            setCourseRecurringThemes(sortedData);

            // Extracting Total and Factor_Name for the horizontal bar chart
            const labels = sortedData.map(item => item.Factor_Name);
            const counts = sortedData.map(item => item.Total);
            setChartData({ labels, counts });
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseLineChart', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const data = response.data;
            const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            const formattedDates = reviewMonths.map(dateStr => {
                const [year, month] = dateStr.split('-');
                const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                return `${monthName} ${year}`;
            });
            const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);

            const LinechartData = {
                formattedDates,
                positiveReviews,
                negativeReviews,
                neutralReviews
            };
            setLineChartData(LinechartData)


            // -------------------- insight no 1 ----------------------
            let previous_3_months_sum = LinechartData.positiveReviews.slice(-6)[0] + LinechartData.positiveReviews.slice(-6)[1] + LinechartData.positiveReviews.slice(-6)[2]
            let last_3_months_sum = LinechartData.positiveReviews.slice(-6)[3] + LinechartData.positiveReviews.slice(-6)[4] + LinechartData.positiveReviews.slice(-6)[5]
            let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
            let avg = (last_3_months_sum + previous_3_months_sum) / 2;
            let percentage = (absoluteDiff / avg) * 100;
            let insight_1_result = percentage.toFixed(1);
            let insight_1_grade = false

            if (last_3_months_sum > previous_3_months_sum) {
                insight_1_grade = true
            } else {
                insight_1_grade = false
            }

            // -------------------- insight no 2 ----------------------
            const previous_1_months_sum = LinechartData.positiveReviews.slice(-2)[0] + LinechartData.negativeReviews.slice(-2)[0] + LinechartData.neutralReviews.slice(-2)[0]
            const last_1_months_sum = LinechartData.positiveReviews.slice(-2)[1] + LinechartData.negativeReviews.slice(-2)[1] + LinechartData.neutralReviews.slice(-2)[1]
            absoluteDiff = Math.abs(last_1_months_sum - previous_1_months_sum);
            avg = (last_1_months_sum + previous_1_months_sum) / 2;
            percentage = (absoluteDiff / avg) * 100;
            let insight_2_result = percentage.toFixed(1);
            let insight_2_grade = false
            if (last_1_months_sum > previous_1_months_sum) {
                insight_2_grade = true
            } else {
                insight_2_grade = false
            }

            setCourseInsights(prevState => ({ ...prevState, insight_1_result, insight_1_grade, insight_2_result, insight_2_grade }))
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseTopReviews', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseTopReviews(response.data)
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseInsights', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseSummaryInsight(JSON.parse(response.data[0].key_themes).slice(0, 3))
        })
    }, [courseID])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourseTopRecommendations', { params: { courseID: courseID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseTopRecommendations(response.data)
        })
    }, [courseID])

    const parseExperiences = (experiences) => {
        try {
            return experiences ? JSON.parse(experiences) : [];
        } catch (error) {
            console.error("Error parsing experiences:", error);
            return [];
        }
    };

    let counter = 0;
    return (
        <main class={style.content}>
            <div class="container p-0 mt-3">
                <h1 class="mb-3">
                    <strong>{courseName.map((data) => (
                        data.Course_Name
                    ))}
                    </strong>
                </h1>

                <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex flex-column flex-lg-row">
                        <div className='col-md-12 col-lg-7 col-xl-8 mt-3 order-md-1'>
                            {
                                courseSummary.map((data) => (
                                    <p class={`${style.text_muted} mb-3`}>This course has an overall rating of {data.TTC_Score.toFixed(1)} out of 5.0 based on {data.Total} total reviews analyzed.</p>
                                ))
                            }
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                {courseSummaryInsight.map((insight, index) => (
                                    <li key={index} class={`${style.text_muted}`}>
                                        {insight}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-5 col-xl-4 mt-2 order-md-2'>
                            <ReactPieChart StarCount={courseStarCount} setPage={setPage} setFilter={setFilter} courseID={courseID} isInstituteLevel={false} />
                        </div>
                    </div>
                </div>

                <div className={`row mb-3 mt-3 ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`} >
                    <div className='col-lg-12 col-xl-6'>
                        <div className='row d-flex'>
                            {courseHeaderData.map((data) => (
                                <>
                                    <div className='col-6 mb-4'>
                                        <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Positive Reviews'} value={((data.Positive / data.Total) * 100).toFixed(0) + '%'} icon={'fa-regular fa-thumbs-up'} />
                                    </div>
                                    <div className='col-6'>
                                        <Header name={'Negative Reviews'} value={data.Negative} icon={'fa-solid fa-check'} />
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`} style={{ display: 'inline-block' }}>Overall Score (1-5)</h5><p className='ml-1' style={{ display: 'inline-block', padding: 0, margin: 0, }}>based on sentiment analysis</p>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <ReactBarChart StarCount={courseStarCount} setPage={setPage} setFilter={setFilter} courseID={courseID} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div className="col-12 d-flex">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div className={`${style.card_header}`}>
                                <h5 className={`${style.card_title}`}>Learner Feedback Analysis: Recurring Themes</h5>
                            </div>
                            <div className={`${style.card_body} px-4`}>
                                <p className={`${style.text_muted} mb-2`}>The following are the recurring themes identified from the learner feedback.</p>
                                {courseRecurringThemes.map((detail, index) => {
                                    const positiveExperiences = parseExperiences(detail.PositiveExperiences);
                                    const negativeExperiences = parseExperiences(detail.NegativeExperiences);

                                    return (
                                        <div key={index} className={`${style.progress_container}`}>
                                            <div className={`${style.progress_title}`} onClick={() => { setPage('reports'); setFilter({ course: courseID, factor: detail.Factor_Name, score: '*' }); }} style={{ cursor: 'pointer' }}>{index + 1}. {detail.Factor_Name}</div>
                                            <div className={`${style.progress_reviews}`}>Reviews: {detail.Total}</div>
                                            <div className={`${style.progress_bar}`}>
                                                <div className={`${style.progress_bar_inner}`} style={{ width: `${(detail.Positive / detail.Total) * 100}%` }}></div>
                                                <div className={`${style.progress_percentage}`}>{((detail.Positive / detail.Total) * 100).toFixed(0)}%</div>
                                            </div>
                                            <div className={`${style.additional_text}`}>
                                                {positiveExperiences.length > 0 && (
                                                    <>
                                                        Positive Experience:
                                                        <ul>
                                                            {positiveExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                                {negativeExperiences.length > 0 && (
                                                    <>
                                                        Negative Experience:
                                                        <ul>
                                                            {negativeExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div className="col-12 d-flex">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div className={`${style.card_header}`}>
                                <h5 className={`${style.card_title}`}>Learner Feedback Analysis: Recurring Themes</h5>
                            </div>

                            {/* Insert the horizontal bar chart here */}
                            <div className="px-4">
                                <ReactHorizontalBarChart
                                    StarCount={chartData.counts} 
                                    setPage={setPage}
                                    setFilter={setFilter}
                                    courseID={courseID}
                                    labels={chartData.labels}
                                />
                            </div>

                            <div className={`${style.card_body} mt-5 px-4`}>
                                <p className={`${style.text_muted} mb-2`}>The following are the recurring themes identified from the learner feedback.</p>
                                {courseRecurringThemes.map((detail, index) => {
                                    const positiveExperiences = parseExperiences(detail.PositiveExperiences);
                                    const negativeExperiences = parseExperiences(detail.NegativeExperiences);

                                    return (
                                        <div key={index} className={`${style.progress_container}`}>
                                            <div className={`${style.progress_title}`} >{index + 1}. {detail.Factor_Name}</div>
                                            {/* onClick={() => { setPage('reports'); setFilter({ course: courseID, factor: detail.Factor_Name, score: '*' }); }} style={{ cursor: 'pointer' }} */}
                                            <div className={`${style.progress_reviews}`}>Reviews: {detail.Total}</div>
                                            <div className={`${style.progress_bar}`}>
                                                <div className={`${style.progress_bar_inner}`} style={{ width: `${(detail.Positive / detail.Total) * 100}%` }}></div>
                                                <div className={`${style.progress_percentage}`}>{((detail.Positive / detail.Total) * 100).toFixed(0)}%</div>
                                            </div>
                                            <div className={`${style.additional_text}`}>
                                                {positiveExperiences.length > 0 && (
                                                    <>
                                                        Positive Experience:
                                                        <ul>
                                                            {positiveExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                                {negativeExperiences.length > 0 && (
                                                    <>
                                                        Negative Experience:
                                                        <ul>
                                                            {negativeExperiences.map((experience, index) => (
                                                                <li key={index}>{experience}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>


                {/* line chart */}
                <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-3 mb-3">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Track the Pulse: Sentiment Trends Over Time</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Stay ahead of the curve and monitor sentiment trends over time with our intuitive sentiment analysis graph.</p>
                                <ReactMultiLineChart lineChartData={lineChartData} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row`}>
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <div class="text-center">
                            <p class={`${style.text_muted}`}>
                                Follow Us on
                                <a href="https://www.linkedin.com/company/senteez/" target="_blank" class="mx-1">LinkedIn</a> |
                                <a href="https://www.instagram.com/senteezanalysis/" target="_blank" class="mx-1">Instagram</a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* header data */}
                {/* <div className="row mb-3">
                    {courseHeaderData.map((data) => (
                        <>
                            <Header name={'Rating'} value={Number(data.TTC_Score.toFixed(1))} icon={'fa-regular fa-star'} />
                            <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-thumbs-up'} />
                            <Header name={'Positive Reviews'} value={((data.Positive / data.Total) * 100).toFixed(0) + '%'} icon={'fa-regular fa-face-smile'} />
                            <Header name={'Negative Reviews'} value={data.Negative} icon={'fa-solid fa-check'} />
                        </>
                    ))}
                </div> */}

                {/* barchart */}
                {/* <div class="row">
                    <div class="col-12 col-md-6 col-xxl-6 d-flex">
                        <div class="card flex-fill w-100">
                            <div class="card-header">
                                <h5 class="card-title mb-0">Overall Results</h5>
                            </div>
                            <div class="card-body px-4">
                                <ReactBarChart StarCount={courseStarCount} />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xxl-6 d-flex">
                        <div class="card flex-fill w-100">
                            <div class="card-header">
                                <h5 class="card-title mb-0">Breakdown Of Sentiments</h5>
                            </div>
                            <div style={{ height: '320px' }} class="card-body d-flex justify-content-center align-items-center">
                                <ReactPieChart StarCount={courseStarCount} />
                            </div>
                        </div>
                    </div>
                </div> */}


                {/* OCSE */}
                {/* <div className={`row mt-3 ${memberGroup === 'Basic' ? `${style.blur_effect}`: ''}`}>
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Analysis By Effectiveness Factors</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Analyzing Course Effectiveness: Breakdown of Sentiment by Key Factors</p>
                                <ReactStackedBarChart FactorsDetail={courseFactorsDetail} />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* recommandations */}
                {/* <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}`: ''}`}>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Top Reviews</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>See the reviews that have made the most impact on your overall rating.</p>
                                <table class={`${style.table} table table-hover my-0`}>
                                    <thead>
                                        <tr>
                                            <th class="text-center d-none d-sm-none d-md-table-cell">#</th>
                                            <th class="col-1 col-sm-1 d-sm-table-cell text-center">Review By</th>
                                            <th class="col-11 col-sm-10 col-md-7 text-center">Review</th>
                                            <th class="d-none d-sm-none d-md-table-cell text-center">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="d-none">
                                            {counter = 0}
                                        </div>
                                        {
                                            courseTopReviews.slice(0, 5).map((data) => (
                                                <tr>
                                                    <td class='text-center d-none d-sm-none d-md-table-cell'>{++counter}</td>
                                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                                    <td class="col-11 col-sm-10 col-md-7">{data.Course_Review}</td>
                                                    <td class="d-none d-sm-none d-md-table-cell text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='text-center mb-3'>
                                    <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { 
                                    ('reports'); setFilter({ course: courseID, factor: '*', score: '5' }) }}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className={`row ${memberGroup === 'Basic' ? `${style.blur_effect}`: ''}`}>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div className={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h4 class={`${style.card_title}`}>Top recommandations: Insights to Elevate Your Teaching</h4>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Discover the top recommendations and areas for improvement extracted from student feedback to enhance your teaching methods and improve overall satisfaction.</p>
                                <table class={`${style.table} table table-hover my-0`}>
                                    <thead>
                                        <tr>
                                            <th class="text-center d-none d-sm-none d-md-table-cell">#</th>
                                            <th class="col-1 col-sm-1 d-sm-table-cell text-center">Review By</th>
                                            <th class="col-11 col-sm-10 col-md-7 text-center">Review</th>
                                            <th class="d-none d-sm-none d-md-table-cell text-center">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="d-none">
                                            {counter = 0}
                                        </div>
                                        {

                                            courseTopRecommendations.map((data) => (
                                                <tr>
                                                    <td class='text-center d-none d-sm-none d-md-table-cell'>{++counter}</td>
                                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                                    <td class="col-11 col-sm-10 col-md-7">{data.Course_Review}</td>
                                                    <td class="d-none d-sm-none d-md-table-cell text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='text-center mb-3'>
                                    <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setFilter({ course: courseID, factor: 'Is_Recommendations_Review', score: '*' }) }}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div class="card flex-fill">
                            <div class="card-header align-center">
                                <h4 class="card-title mb-0 text-center font-weight-bold">Recommendations</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th class="text-center col-1">#</th>
                                        <th class="d-none d-xl-table-cell col-2 text-center">Review By</th>
                                        <th class="d-none d-xl-table-cell col-7 text-center">Review</th>
                                        <th class="d-none d-xl-table-cell col-2 text-center">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div className="d-none">
                                        {counter = 0}
                                    </div>
                                    {

                                        courseTopRecommendations.map((data) => (
                                            <tr>
                                                <td class='text-center col-1'>{++counter}</td>
                                                <td class="d-none d-xl-table-cell col-2 text-center">{data.Display_Name}</td>
                                                <td class="d-none d-xl-table-cell col-8">{data.Course_Review}</td>
                                                <td class="d-none d-xl-table-cell col-1 text-center">{new Date(data.Review_Created_At).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='text-center'>
                                <Link className="btn btn-primary btn-sm mt-3 mx-auto" to="" onClick={() => { setPage('reports'); setReportCourseID(courseID); setReportTopReviews('Is_Recommendations_Review'); }}>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div class="card flex-fill">
                            <div class="card-header align-center">
                                <h4 class="card-title mb-0 text-center font-weight-bold">Course Summary</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="d-none d-xl-table-cell">Total Count</th>
                                        <th class="d-none d-xl-table-cell">Total Positive</th>
                                        <th>Total Negative</th>
                                        <th class="d-none d-md-table-cell">TTC Score</th>
                                        <th class="d-none d-md-table-cell">Star Count(1-5)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        courseSummary.map((data) => (
                                            <tr>
                                                <td>{data.Instructor_ID}</td>
                                                <td class="d-none d-xl-table-cell">{data.Total}</td>
                                                <td class="d-none d-xl-table-cell">{data.Positive}</td>
                                                <td class="d-none d-xl-table-cell">{data.Negative}</td>
                                                <td class="d-none d-xl-table-cell">{data.TTC_Score}</td>
                                                <td class="d-none d-xl-table-cell">{data.Star_Count}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 col-xxl-12 d-flex mt-4 mb-4">
                        <div class="card flex-fill">
                            <div class="card-header align-center">
                                <h4 class="card-title mb-0 text-center font-weight-bold">Course Summary By Factors</h4>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th class="d-none d-xl-table-cell">Factors</th>
                                        <th class="d-none d-xl-table-cell">Total Count</th>
                                        <th class="d-none d-xl-table-cell">Total Positive</th>
                                        <th class="d-none d-xl-table-cell">Total Neutral</th>
                                        <th class="d-none d-xl-table-cell">Total Negative</th>
                                        <th class="d-none d-md-table-cell">TTC Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        courseSummaryByFactors.map((data) => (
                                            <tr>
                                                <td>{data.Instructor_ID}</td>
                                                <td class="d-none d-xl-table-cell">{data.Factor_Name}</td>
                                                <td class="d-none d-xl-table-cell">{data.Total}</td>
                                                <td class="d-none d-xl-table-cell">{data.Positive}</td>
                                                <td class="d-none d-xl-table-cell">{data.Neutral}</td>
                                                <td class="d-none d-xl-table-cell">{data.Negative}</td>
                                                <td class="d-none d-xl-table-cell">{data.TTC_Score}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}

            </div>
        </main>
    )
}
