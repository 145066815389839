import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReactBarChart = ({ StarCount, setPage, setFilter, courseID }) => {
  const labels = ["😠", "😟", "😐", "🙂", "😄"];
  const options = {
    // indexAxis: 'y', // This makes the bars horizontal
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          color: 'black',
          font: {
            size: 18
          }
        }
      },
      y: {
        ticks: {
          color: 'black',
        }
      }
    },
    // onClick: (event, elements) => {
    //   if (elements.length > 0) {
    //     const index = elements[0].index;
    //     const label = labels[index];
    //     let score;

    //     if (label === "😠") {
    //       score = '1';
    //     } else if (label === "😟") {
    //       score = '2';
    //     } else if (label === "😐") {
    //       score = '3';
    //     } else if (label === "🙂") {
    //       score = '4';
    //     } else if (label === "😄") {
    //       score = '5';
    //     }

    //     setPage('reports');
    //     setFilter({ course: courseID, factor: '*', score: score });
    //   }
    // }
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'Sentiment',
        data: StarCount,
        backgroundColor: [
          '#FF0000',
          '#f48924',
          '#ffc845',
          '#7552cc',
          '#037ef3'
        ],
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'end',
          offset: -5,
          color: '#000',
          font: {
            size: 14,
            weight: 'bold'
          },
        },
        borderColor: "black",
        borderWidth: 2,
      }
    ],

  };
  return <Bar options={options} data={data} />
}

export default ReactBarChart
