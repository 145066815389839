import './App.css';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Institute from './Components/Institute';
import Instructor from './Components/Instructor';
import Dashboard from './Components/Dashboard/Dashboard';
import InstituteState from './Context/InsituteState';
import SignIn from './Components/InstituteSignIn/SignIn';
import PrivateRoute from './Components/PrivateRoute';
import DataEntry from './Components/DataEntry';
import CoreCleaner from './Components/CoreCleaner';
import ReviewClassificationCleaning from './Components/ReviewClassificationCleaning';
import ProtectedAdminRoute from './ProtectedAdminRoute';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [page, setPage] = useState('instructor');
  const [filter, setFilter] = useState({
    course: '*',
    factor: '*',
    score: '*'
  });
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem('admin') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('admin', isAdmin);
  }, [isAdmin]);

  Axios.defaults.withCredentials = true;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<ProtectedAdminRoute component={Institute} isAdmin={isAdmin} setIsAdmin={setIsAdmin} />}
          />
          <Route path="/SignIn" element={<SignIn isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setIsAdmin={setIsAdmin} />} />{/*<Instructor />*/}
          <Route
            path="/institute"
            element={<PrivateRoute component={Instructor} isAuthenticated={isAuthenticated} page={page} setPage={setPage} filter={filter} setFilter={setFilter} />}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute component={Dashboard} isAuthenticated={isAuthenticated} page={page} setPage={setPage} filter={filter} setFilter={setFilter} />}
          />
          <Route
            path="/DataEntry"
            element={<ProtectedAdminRoute component={DataEntry} isAdmin={isAdmin} />}
          />
          <Route
            path="/DataCleaning"
            element={<ProtectedAdminRoute component={CoreCleaner} isAdmin={isAdmin} />}
          />
          <Route
            path="/ClassifiedDataCleaning"
            element={<ProtectedAdminRoute component={ReviewClassificationCleaning} isAdmin={isAdmin} />}
          />
          {/* <Route path="/" element={<Institute />} /> */}
          {/* <Route path="/institute" element={<Instructor />} /> */}
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/DataEntry" element={<DataEntry />} /> */}
          {/* <Route path="/DataCleaning" element={<CoreCleaner />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

