import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Header from './DashboardInstructorContent/InstructorHeaders/Header'
import style from './DashboardInstructorContent/InstructorContent.module.css'
import navStyle from './DashboardNavbar/Navbar.module.css'
import ReactPieChart from './ReactPieChart';
// './InstructorHeaders/Header';
import { hashedApiKey } from '../api'
import { API_BASE_URL } from '../api';

export default function Instructor({page, setPage, filter, setFilter}) {
    const [instituteID, setInstituteID] = useState(JSON.parse(localStorage.getItem('institute')))
    const [instructorList, setInstructorList] = useState([])
    const [headerData, setHeaderData] = useState([])
    const [instituteStarCount, setInstituteStarCount] = useState([])
    const [instituteInsights, setInstituteInsights] = useState([])
    const navigate = useNavigate();
    const [instructorInsights, setInstructorInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    useEffect(() => {
        const validateInstitute = async () => {
            try {
                const response = await Axios.get(API_BASE_URL + '/api/validate-institute', {
                    params: { instituteID: instituteID },
                    headers: { 'x-api-key': hashedApiKey }
                });

                if (!response.data.isValid) {
                    if (response.data.sessionInstituteId === undefined) {
                        navigate('/SignIn');
                        localStorage.clear();
                    } else {
                        localStorage.setItem('institute', JSON.stringify(response.data.sessionInstituteId));
                        setInstituteID(response.data.sessionInstituteId);
                    }
                    // Show alert message
                    // alert("⛔ Unauthorized access");

                    window.location.reload();
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateInstitute();
    }, [instituteID]);

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructors', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstructorList(response.data)
            const instructorWithHighestTTC = response.data.reduce((acc, cur) => {
                if (cur.TTC_Score > acc.TTC_Score) {
                    return cur;
                } else {
                    return acc;
                }
            });
            const insight_3_name = instructorWithHighestTTC.Instructor_Name
            const insight_3_score = instructorWithHighestTTC.TTC_Score.toFixed(1)
            const insight_3_total = instructorWithHighestTTC.Total

            setInstructorInsights(prevState => ({ ...prevState, insight_3_name, insight_3_score, insight_3_total }))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstituteInsights', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            // console.log(JSON.parse(response.data[0].key_themes))
            setInstituteInsights(JSON.parse(response.data[0].key_themes))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstituteSummary', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            // console.log(response.data[0].Star_Count.split(',').map(Number))
            setInstituteStarCount(response.data[0].Star_Count.split(',').map(Number))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstituteLineChart', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            const data = response.data;
            const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
            const formattedDates = reviewMonths.map(dateStr => {
                const [year, month] = dateStr.split('-');
                const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                return `${monthName} ${year}`;
            });
            const positiveReviews = data.map(item => item.positive_reviews).slice(0, -1).slice(-12);
            const negativeReviews = data.map(item => item.negative_reviews).slice(0, -1).slice(-12);
            const neutralReviews = data.map(item => item.neutral_reviews).slice(0, -1).slice(-12);
            const LinechartData = {
                formattedDates,
                positiveReviews,
                negativeReviews,
                neutralReviews
            };
            // -------------------- insight no 1 ----------------------
            let previous_3_months_sum = LinechartData.positiveReviews.slice(-6)[0] + LinechartData.positiveReviews.slice(-6)[1] + LinechartData.positiveReviews.slice(-6)[2]
            let last_3_months_sum = LinechartData.positiveReviews.slice(-6)[3] + LinechartData.positiveReviews.slice(-6)[4] + LinechartData.positiveReviews.slice(-6)[5]
            let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
            let avg = (last_3_months_sum + previous_3_months_sum) / 2;
            let percentage = (absoluteDiff / avg) * 100;
            let insight_1_result = percentage.toFixed(1);
            let insight_1_grade = false

            if (last_3_months_sum > previous_3_months_sum) {
                insight_1_grade = true
            } else {
                insight_1_grade = false
            }
            // -------------------- insight no 2 ----------------------
            const previous_1_months_sum = LinechartData.positiveReviews.slice(-2)[0] + LinechartData.negativeReviews.slice(-2)[0] + LinechartData.neutralReviews.slice(-2)[0]
            const last_1_months_sum = LinechartData.positiveReviews.slice(-2)[1] + LinechartData.negativeReviews.slice(-2)[1] + LinechartData.neutralReviews.slice(-2)[1]
            absoluteDiff = Math.abs(last_1_months_sum - previous_1_months_sum);
            avg = (last_1_months_sum + previous_1_months_sum) / 2;
            percentage = (absoluteDiff / avg) * 100;
            let insight_2_result = percentage.toFixed(1);
            let insight_2_grade = false
            if (last_1_months_sum > previous_1_months_sum) {
                insight_2_grade = true
            } else {
                insight_2_grade = false
            }

            setInstructorInsights(prevState => ({ ...prevState, insight_1_result, insight_1_grade, insight_2_result, insight_2_grade }))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstituteSummary', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            // console.log(response.data)
            // const dummyInstituteSummary = [{
            //     Generated_On: "2024-04-05T07:17:27.000Z",
            //     Institute_ID: 80,
            //     Institute_Name: "Institute of Coding",
            //     Institute_Summary_ID: 88,
            //     Negative: 48,
            //     Neutral: 162,
            //     Positive: 1850,
            //     Star_Count: "6,42,162,705,1145",
            //     TTC_Score: 4.29029,
            //     Total: 3060
            // }]
            setHeaderData(response.data)
        })
    }, [])

    const modalStyles = {
        modalLg: {
            maxWidth: '80%',
        },
        modalHeader: {
            backgroundColor: '#007bff',
            color: 'white',
        },
        modalTitle: {
            fontWeight: 'bold',
        },
        modalBody: {
            fontSize: '1.1em',
            minHeight: '50vh',
        },
        listStyleNone: {
        },
        listItem: {
            marginBottom: '15px',
        },
        textMuted: {
            color: '#6c757d !important',
        },
    };

    const handleLogout = () => {
        localStorage.clear();
        // Reload the page
        window.location.reload();
    };
    const textArray = [
        "Hayley, a veterinary nurse, has extensive experience in animal welfare, education, and anaesthesia, with roles at the University of Edinburgh and animal welfare projects, earning multiple awards including an MBE.",
        'Areti, a Teaching and Research Fellow at the University of Edinburgh, focuses on applied AI in healthcare, particularly knowledge-based systems, and has extensive experience in science communication and computer science education.',
        'Charles Cockell, Professor of Astrobiology at the University of Edinburgh, directs the UK Centre for Astrobiology, with research on microbes in extreme environments. He\'s also an author, Senior Editor of Astrobiology, and founder of the Earth and Space Foundation.'
    ];

    return (
        <>
            <nav class={`${navStyle.navbar} navbar-expand navbar-light navbar-bg`}>
                <a href='https://senteez.com/' class={navStyle.sidebar_brand} target="_blank">
                    <span>SENTEEZ</span><span className={navStyle.sub}> - Revolutionizing Customer Feedback</span>
                </a>
                <div className='row ml-auto'>
                    <a className='btn btn-danger btn-sm mr-2' onClick={handleLogout}>Logout</a>
                </div>
            </nav>
            <main class={style.content} >
                <div className='container'>
                    <h1 class="mb-3 mt-3"><strong>{headerData.map((data) => (data.Institute_Name))}</strong></h1>
                    <div className="row mb-3 mt-3" >
                        {headerData.map((data) => (
                            <>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                                    <Header name={'Rating'} value={`${Number(data.TTC_Score.toFixed(1))} / 5.0`} icon={'fa-regular fa-star'} />
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                                    <Header name={'Total Reviews'} value={data.Total} icon={'fa-regular fa-face-smile'} />
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                                    <Header name={'Total Instructors'} value={instructorList.length} icon={'fa-solid fa-person-chalkboard'} />
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3'>
                                    <Header name={'Courses Analyzed'} value={instructorList.reduce((total, instructor) => total + instructor.total, 0)} icon={'fa-solid fa-check'} />
                                </div>
                            </>
                        ))}

                    </div>

                    <div class="p-0">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class="card-body d-flex flex-column flex-lg-row">
                                <div className='col-md-12 col-lg-7 col-xl-8 mt-3 order-md-1'>
                                    {headerData.map((data, index) => (
                                        // <p class={`${style.text_muted} mb-3`}>{data.Institute_Name} has an overall rating of {Number(data.TTC_Score.toFixed(1))} out of 5.0 based on {data.Total} total reviews analyzed.</p>
                                        <p key={index} className={`${style.text_muted} mb-3`}>
                                            {data.Institute_Name} has an overall rating of {Number(data.TTC_Score.toFixed(1))} out of 5.0 based on {data.Total} total reviews analyzed.
                                        </p>
                                    ))}
                                    <h5 className={`${style.card_title}`}>Key Insights:</h5>
                                    <ul>
                                        {instituteInsights.slice(0, 3).map((insight, index) => (
                                            <li key={index} className={`${style.text_muted}`}>
                                                {insight}
                                            </li>
                                        ))}
                                    </ul>
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#insightsModal">
                                        View All
                                    </button>

                                    <div className="modal fade" id="insightsModal" tabIndex="-1" role="dialog" aria-labelledby="insightsModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" style={modalStyles.modalLg} role="document">
                                            <div className="modal-content">
                                                <div className="modal-header" style={modalStyles.modalHeader}>
                                                    <h5 className="modal-title" id="insightsModalLabel" style={modalStyles.modalTitle}>All Insights</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body" style={modalStyles.modalBody}>
                                                    <ul style={modalStyles.listStyleNone}>
                                                        {instituteInsights.map((insight, index) => (
                                                            <li key={index} style={{ ...modalStyles.textMuted, ...modalStyles.listItem }}>
                                                                {insight}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-md-12 col-lg-5 col-xl-4 mt-2 order-md-2`}>
                                    <ReactPieChart StarCount={instituteStarCount} setPage={setPage} setFilter={setFilter} isInstituteLevel={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            {instructorList.map((data, index) => (
                                <div class="col-md-4 col-sm-6 mb-4">
                                    <div class={`${style.card} flex-fill w-100`}>
                                        <img class="card-img-top" style={{ 'height': '150px' }} src="/dummy.png" alt="Unsplash" />
                                        <div class={`${style.card_header}`}>
                                            <h5 class={`${style.card_title}`}>{data.Instructor_Name}</h5>
                                        </div>
                                        <div class={`${style.card_body} px-4`}>
                                            <p class={`${style.text_muted} mb-2`}>{textArray[index % 3]}</p>
                                            <Link className='btn btn-primary btn-sm mt-3 mx-auto' to={`/dashboard`} onClick={() => { localStorage.setItem('instructor', JSON.stringify(data.Instructor_ID)); }}>Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={`row`}>
                        <div class="col-12 d-flex justify-content-center mb-3">
                            <div class="text-center">
                                <p class={`${style.text_muted}`}>
                                    Follow Us on
                                    <a href="https://www.linkedin.com/company/senteez/" target="_blank" class="mx-1">LinkedIn</a> |
                                    <a href="https://www.instagram.com/senteezanalysis/" target="_blank" class="mx-1">Instagram</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
