import React, { useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';

export default function ReviewClassificationCleaning() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
    const [instituteNameList, setInstituteNameList] = useState([]);
    const [factorList, setFactorList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [reviewByFactorList, setReviewByFactorList] = useState([]);
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedInstituteName, setSelectedInstituteName] = useState("Select a Institute");
    const [selectedCourseName, setSelectedCourseName] = useState("Select a Course");
    const [reviewByLLama3LLMFactors, setReviewByLLama3LLMFactors] = useState([]);
    const [reviewByMistralLLMFactors, setReviewByMistralLLMFactors] = useState([]);
    const [reviewByMixtralLLMFactors, setReviewByMixtralLLMFactors] = useState([]);
    const [reviewByGemma2LLMFactors, setReviewByGemma2LLMFactors] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/validate-admin`, {
                    params: { isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                if (!response.data.isValid) {
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid);
                    navigate('/SignIn');
                }
            } catch (error) {
                console.error(error);
            }
        };
        validateAdmin();
    }, [isAdmin]);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/getInstitute`, { headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                setInstituteNameList(response.data);
            })
            .catch((error) => console.error('Error fetching brands:', error));
    }, []);

    useEffect(() => {
        if (selectedCourse) {
            axios.get(`${API_BASE_URL}/api/getFactorsByCourseID`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setFactorList(response.data))
                .catch((error) => console.error('Error fetching factors:', error));

            axios.get(`${API_BASE_URL}/api/getReviewsWithFactors`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setReviewByFactorList(response.data))
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getLLama3LLMResults`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByLLama3LLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getMistralLLMResults`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByMistralLLMFactors(response.data)
                    console.log(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));


            axios.get(`${API_BASE_URL}/api/getMixtralLLMResults`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByMixtralLLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

            axios.get(`${API_BASE_URL}/api/getGemma2LLMResults`, {
                params: { courseID: selectedCourse },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => {
                    setReviewByGemma2LLMFactors(response.data)
                })
                .catch((error) => console.error('Error fetching reviews:', error));

        } else {
            setFactorList([]);
            setReviewByFactorList([]);
            setReviewByLLama3LLMFactors([]);
            setReviewByMistralLLMFactors([]);
            setReviewByMixtralLLMFactors([]);
            setReviewByGemma2LLMFactors([]);
        }
    }, [selectedCourse]);

    useEffect(() => {
        if (selectedInstitute) {
            axios.get(`${API_BASE_URL}/api/getCoursesByInstituteID`, {
                params: { instituteID: selectedInstitute },
                headers: { 'x-api-key': hashedApiKey }
            })
                .then((response) => setCourseList(response.data))
                .catch((error) => console.error('Error fetching course:', error));
        }
    }, [selectedInstitute]);

    const handleInstituteSelect = (instituteID) => {
        setSelectedInstitute(instituteID);
        setSelectedInstituteName(instituteID === '*' ? "All Institutes" : instituteNameList.find((institute) => institute.Institute_ID === instituteID)?.Institute_Name || "Select a Institute");
        setSelectedCourse(null);
    };

    const handleCourseSelect = (courseID) => {
        setSelectedCourse(courseID);
        setSelectedCourseName(courseList.find((course) => course.Course_ID === courseID)?.Course_Name || "Select a Course");
    };

    const handleFactorChange = debounce(async (reviewId, factorId, isChecked) => {
        try {
            // Optimistic UI update
            setReviewByFactorList((prevList) =>
                prevList.map((review) => {
                    if (review.Review_ID === reviewId) {
                        const updatedFactors = isChecked
                            ? [...review.Factor_IDs, factorId] // Add factor
                            : review.Factor_IDs.filter((id) => id !== factorId); // Remove factor
                        return { ...review, Factor_IDs: updatedFactors };
                    }
                    return review;
                })
            );

            // Make the API request to reflect the change
            if (isChecked) {
                // Adding a factor
                await axios.post(`${API_BASE_URL}/api/addReviewToFactor`, {
                    reviewID: reviewId,
                    factorID: factorId
                }, {
                    headers: { 'x-api-key': hashedApiKey },
                    withCredentials: true
                });
            } else {
                // Removing a factor
                await axios.delete(`${API_BASE_URL}/api/removeReviewFromFactor`, {
                    params: { reviewID: reviewId, factorID: factorId },
                    headers: { 'x-api-key': hashedApiKey },
                    withCredentials: true
                });
            }
        } catch (error) {
            console.error('Error updating review-factor association:', error);
            alert('Failed to update factor association. Please try again.');

            // Roll back the optimistic update
            setReviewByFactorList((prevList) =>
                prevList.map((review) => {
                    if (review.Review_ID === reviewId) {
                        const originalFactors = isChecked
                            ? review.Factor_IDs.filter((id) => id !== factorId) // Revert addition
                            : [...review.Factor_IDs, factorId]; // Revert removal
                        return { ...review, Factor_IDs: originalFactors };
                    }
                    return review;
                })
            );
        }
    }, 300); // 300ms debounce delay

    const tableStyles = {
        tableLayout: 'fixed',
        width: '100%',
    };

    const cellStyles = {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <div className="container mt-3">
            <div className="form-group">
                <div className="col-12 col-md-4 mb-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="brandDropdownButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {selectedInstituteName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="brandDropdownButton">
                            {instituteNameList.map((institute) => (
                                <a className="dropdown-item" href="#" onClick={() => handleInstituteSelect(institute.Institute_ID)} key={institute.Institute_ID}>
                                    {institute.Institute_Name}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    {selectedInstitute && (
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="productDropdownButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {selectedCourseName}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="productDropdownButton">
                                {courseList.map((course) => (
                                    <a className="dropdown-item" href="#" onClick={() => handleCourseSelect(course.Course_ID)} key={course.Course_ID}>
                                        {course.Course_Name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="table-responsive">
                    <table className="table" style={tableStyles}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col" className="col-1" style={cellStyles}>#</th>
                                <th scope="col" className="col-5" style={cellStyles}>Institute Review</th>
                                <th scope="col" className="col-6" style={cellStyles}>Factors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviewByFactorList.map((review, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <th scope="row" className="text-truncate" style={cellStyles}>{review.Review_ID}</th>
                                        <td className="text-truncate" style={cellStyles}>{review.Course_Review}</td>
                                        <td>
                                            <div className="row">
                                                {factorList.map((factor) => {
                                                    const isChecked = review.Factor_IDs && review.Factor_IDs.includes(factor.Factor_ID);

                                                    return (
                                                        <div key={factor.Factor_ID} className="col-6 mb-2 text-truncate">
                                                            <label className="d-inline-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isChecked}
                                                                    onChange={(e) => handleFactorChange(review.Review_ID, factor.Factor_ID, e.target.checked)}
                                                                />
                                                                <span className="ml-2">{factor.Factor_Name}</span>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">
                                            <p>
                                                <strong>LLama3</strong> = {
                                                    reviewByLLama3LLMFactors.find(llama3Review => llama3Review.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Mistral</strong> = {
                                                    reviewByMistralLLMFactors.find(mistralReview => mistralReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Gemma2</strong> = {
                                                    reviewByGemma2LLMFactors.find(Gemma2Review => Gemma2Review.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                            <p>
                                                <strong>Mixtral</strong> = {
                                                    reviewByMixtralLLMFactors.find(mixtralReview => mixtralReview.review_id === review.Review_ID)?.factor_names || 'No factors found'
                                                }
                                            </p>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}