import Axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { hashedApiKey } from '../api'
import { API_BASE_URL } from '../api';

export default function Institute({isAdmin, setIsAdmin}) {
    const [instituteList, setInstituteList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await Axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin:isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });
                
                if (!response.data.isValid){
                    // console.log(response.data.isValid)
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(JSON.stringify(response.data.isValid))

                    window.location.reload();   
                }
                // if (!response.data.isValid) {
                //     if (response.data.sessionInstituteId === undefined) {
                //         navigate('/SignIn');
                //         localStorage.clear();
                //     }else{
                //         localStorage.setItem('institute', JSON.stringify(response.data.sessionInstituteId));
                //         // setInstituteID(response.data.sessionInstituteId);
                //     }
                //     // Show alert message
                //     // alert("⛔ Unauthorized access");

                //     window.location.reload();
                // }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);


    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstitute', {
            headers: {
                'x-api-key': hashedApiKey
            }
        }).then((response) => {
            setInstituteList(response.data)
        })
    }, [])

    
    const handleLogout = () => {
        localStorage.clear();
        
        window.location.reload();
    };

    return (
        <>
            <div className='row ml-auto'>
                <a className='btn btn-danger btn-sm mr-2' onClick={handleLogout}>Logout</a>
            </div>
            <table className="table">
                {/* <PythonWordCloud /> */}
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Institute Name</th>
                        <th scope="col">Total Instructors</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        instituteList.map((data) => (
                            <tr>
                                <th scope="row">{data.Institute_ID}</th>
                                <td>{data.Institute_Name}</td>
                                <td>{data.total}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </>
    )
}

