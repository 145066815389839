import React from 'react'
import style from './DashboardInstructorContent/InstructorContent.module.css'
import { useNavigate } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export default function ReactPieChart({ StarCount, setPage, setFilter, courseID, isInstituteLevel }) {
    const navigate = useNavigate();
    let Positive = StarCount[3] + StarCount[4]
    let Neutral = StarCount[2]
    let Negative = StarCount[0] + StarCount[1]
    const data = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            label: 'Reviews',
            data: [Positive, Neutral, Negative],
            backgroundColor: [
                '#037ef3',
                '#ffc845',
                '#FF0000'
            ],
            borderColor: "black",
            borderWidth: 2,
        }]
    }

    const options = {
        responsive: true,
        cutout: 0,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    padding: 20
                }

            },
            datalabels: false
        },
        maintainAspectRatio: false,
        // onClick: (event, elements) => {
        //     if (elements.length > 0) {
        //         const index = elements[0].index;
        //         const label = data.labels[index];
        //         let score;

        //         if (label === 'Positive') {
        //             score = '5';
        //         } else if (label === 'Neutral') {
        //             score = '3';
        //         } else if (label === 'Negative') {
        //             score = '1';
        //         }

        //         // setPage('reports');
        //         if (isInstituteLevel) {
        //             setPage('reports');
        //             setFilter({ course: '*', factor: '*', score: score });
        //             navigate('/dashboard');
        //         } else {
        //             setFilter({ course: courseID, factor: '*', score: score });
        //             setPage('reports');
        //         }
        //     }
        // }
    }

    return (
        <div className={`${style.pie_chart}`}>
            <Doughnut data={data} options={options} />
        </div>
    )
}
