import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { API_BASE_URL } from '../api';
import { hashedApiKey } from '../api';

export default function CoreCleaner() {
    const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('admin')))
    const [instituteNameList, setInstituteNameList] = useState([]);
    const [reviewsList, setReviewsList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [filter, setFilter] = useState([]);
    const reviewsTableRef = useRef(null);

    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [selectedInstituteName, setSelectedInstituteName] = useState("Select a Institute");
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCourseName, setSelectedCourseName] = useState("Select a Course");

    useEffect(() => {
        const validateAdmin = async () => {
            try {
                const response = await axios.get(API_BASE_URL + '/api/validate-admin', {
                    params: { isAdmin: isAdmin },
                    headers: { 'x-api-key': hashedApiKey }
                });

                if (!response.data.isValid) {
                    // console.log(response.data.isValid)
                    localStorage.setItem('admin', JSON.stringify(response.data.isValid));
                    setIsAdmin(response.data.isValid)

                    window.location.reload();
                }
            } catch (error) {
                // Handle error if any
            }
        };

        validateAdmin();
    }, [isAdmin]);



    useEffect(() => {
        axios.get(API_BASE_URL + '/api/getInstitute',
            { headers: { 'x-api-key': hashedApiKey } }).then((response) => {
                setInstituteNameList(response.data);
            });
    }, []);

    useEffect(() => {
        if (selectedCourse) {
            axios.get(API_BASE_URL + '/api/getReviewByCourseID', { params: { courseID: selectedCourse }, headers: { 'x-api-key': hashedApiKey } })
                .then((response) => {
                    // Sort the data based on 'Senteez_Score' column in ascending order
                    const sortedData = response.data.sort((a, b) => a.Senteez_Score - b.Senteez_Score);
                    // Update the reviewsList state with the sorted data
                    setReviewsList(sortedData);
                });
        }
    }, [selectedCourse]);

    useEffect(() => {
        if (selectedInstitute === '*') {
            // Handle the case where 'All Brands' is selected
            // You can fetch all products here or reset the product list
            setCourseList([]); // Set an empty array initially, replace with actual product data
        } else {
            // Fetch products for the selected brand
            axios.get(API_BASE_URL + '/api/getCoursesByInstituteID', { params: { instituteID: selectedInstitute }, headers: { 'x-api-key': hashedApiKey } })
                .then((response) => {
                    setCourseList(response.data);
                });
        }
    }, [selectedInstitute]);

    // useEffect(() => {
    //     if (reviewsList.length > 0) {
    //         $(reviewsTableRef.current).DataTable();
    //     }
    // }, [reviewsList]);

    const updateSenteezScore = async (index, model, reviewId, newValue) => {
        const newReviewsList = [...reviewsList];
        newReviewsList[index][model] = newValue;
        setReviewsList(newReviewsList);
        try {
            await axios.put(API_BASE_URL + '/api/updateScore', { Senteez_Score: newValue, reviewId: reviewId },
                { headers: { 'x-api-key': hashedApiKey } });
        } catch (error) {
            console.error(error);
        }
    };

    const handleInstituteSelect = (instituteID) => {
        setSelectedInstitute(instituteID);
        setSelectedInstituteName(instituteID === '*' ? "All Institutes" : instituteNameList.find((institute) => institute.Institute_ID === instituteID)?.Institute_Name || "Select a Institute");
        setSelectedCourse(null); // Reset selected product when changing brands
    };

    const handleCourseSelect = (courseID) => {
        setSelectedCourse(courseID);
        setSelectedCourseName(courseID === '*' ? "All courses" : courseList.find((course) => course.Course_ID === courseID)?.Course_Name || "Select a course");
    };


    return (
        <div className="container mt-3">
            <div className="form-group">
                <div className="col-12 col-md-4 mb-3">
                    <div className="dropdown">
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {selectedInstituteName}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                            <a className="dropdown-item" href="#" onClick={() => handleInstituteSelect('*')}>
                                All Institutes
                            </a>
                            {instituteNameList.map((institute) => (
                                <a className="dropdown-item" href="#" onClick={() => handleInstituteSelect(institute.Institute_ID)} key={institute.Institute_ID}>
                                    {institute.Institute_Name}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    {selectedInstitute && (
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="productDropdownButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {selectedCourseName}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="productDropdownButton" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                                {/* Fetch and render products for the selected brand */}
                                {courseList.map((course) => (
                                    <a className="dropdown-item" href="#" onClick={() => handleCourseSelect(course.Course_ID)} key={course.Course_ID}>
                                        {course.Course_Name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="table-responsive">
                    <table className="table" ref={reviewsTableRef}>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Course Review</th>
                                <th scope="col">Senteez_Score</th>
                                {/* <th scope="col">Bert</th> */}
                                <th scope="col">Gemma2</th>
                                <th scope="col">Openchat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reviewsList.map((data, index) => (
                                <tr key={index}>
                                    <th scope="row">{data.Review_ID}</th>
                                    <td className="col-8 col-sm-8 col-md-7">{data.Course_Review}</td>
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">
                                        <select
                                            className="form-control form-control-sm"
                                            value={data.Senteez_Score}
                                            onChange={(e) => updateSenteezScore(index, 'Senteez_Score', data.Review_ID, e.target.value)}
                                        >
                                            <option value="">--Select--</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </td>
                                    {/* <td className="col-1 col-sm-1 d-sm-table-cell text-center">{data.Sentiment_Score_M1}</td> */}
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">{data.Sentiment_Score_M3}</td>
                                    <td className="col-1 col-sm-1 d-md-table-cell text-center">{data.Sentiment_Score_M2}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

