import React, { useState, useEffect } from 'react'
import Axios from 'axios'

import ReactStackedBarChart from '../ReactStackedBarChart'
import ReactDonetChart from '../ReactDonetChart';
import style from '../DashboardInstructorContent/InstructorContent.module.css'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

export default function OCES({ memberGroup,id, setPage, setFilter }) {
    const [instructorName, setInstructorName] = useState([])
    const [donutTotal, setDonutTotal] = useState([])
    const [donutPositive, setDonutPositive] = useState([])
    const [donutName, setDonutName] = useState([])
    const [instructorFactorsDetail, setInstructorFactorsDetail] = useState({})
    const [instructorSummaryByFactors, setInstructorSummaryByFactors] = useState([])
    const [competitorSummaryByFactors, setCompetitorSummaryByFactors] = useState([])
    const [instructorData, setInstructorData] = useState({
        InstructorOverall: 0,
        InstructorContent: 0,
        InstructorEngagement: 0,
        InstructorPractice: 0,
        InstructorCareerBenefit: 0
    });
    const [competitorData, setCompetitorData] = useState({
        CompetitorOverall: 0,
        CompetitorContent: 0,
        CompetitorEngagement: 0,
        CompetitorPractice: 0,
        CompetitorCareerBenefit: 0
    });

    const [instructorInsights, setInstructorInsights] = useState({
        insight_1_result: 0,
        insight_1_grade: false,
        insight_2_result: 0,
        insight_2_grade: false
    });

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorName', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            // const Name = response.data[0].Instructor_Name
            setInstructorName(response.data)
        })
    })

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstructorSummaryByFactors', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                console.log("institute_id",id)
                const factorsdetail = {
                    'positive': response.data.map(record => record.Positive),
                    'negative': response.data.map(record => record.Negative),
                    'neutral': response.data.map(record => record.Neutral)
                }
                console.log('summary-by-factor',response.data)
                setDonutName(response.data.map(record => record.Factor_Name).slice(0, -1))
                setDonutPositive(response.data.map(record => record.Positive).slice(0, -1))
                setDonutTotal(response.data.map(record => record.Total).slice(0, -1))
                setInstructorFactorsDetail(factorsdetail)
                setInstructorSummaryByFactors(response)
                const instructorContentPositive = response.data[0].Positive
                const instructorPracticePositive = response.data[2].Positive
                Axios.get(API_BASE_URL + '/api/getCompetitorSummaryByFactors', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } })
                    .then((response) => {
                        const competitorContentPositive = response.data[0].Positive
                        const competitorPracticePositive = response.data[2].Positive
                        const percentage_1 = ((instructorContentPositive - competitorContentPositive) / competitorContentPositive) * 100
                        const insight_1_result = percentage_1.toFixed(1)
                        const percentage_2 = ((instructorPracticePositive - competitorPracticePositive) / competitorPracticePositive) * 100
                        const insight_2_result = percentage_2.toFixed(1)
                        setInstructorInsights(prevState => ({ ...prevState, insight_1_result, insight_2_result }))
                    });
            });

        // ((instructorPositive - compatitorPositive) / compatitorPositive) * 100
        Axios.get(API_BASE_URL + '/api/getOCSELineChart', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } })
            .then((response) => {
                const data = response.data;
                // console.log(response.data)
                const reviewMonths = data.map(item => item.review_month).slice(0, -1).slice(-12);
                const formattedDates = reviewMonths.map(dateStr => {
                    const [year, month] = dateStr.split('-');
                    const monthName = new Date(`${dateStr}-01`).toLocaleString('default', { month: 'short' });
                    return `${monthName} ${year}`;
                });
                const pcontent = data.map(item => item.content_positive_reviews).slice(0, -1).slice(-12);
                const pengagement = data.map(item => item.engagement_positive_reviews).slice(0, -1).slice(-12);
                const ppractice = data.map(item => item.practice_positive_reviews).slice(0, -1).slice(-12);
                const pcareerbenefit = data.map(item => item.careerbenefit_positive_reviews).slice(0, -1).slice(-12);
                const LinechartData = {
                    formattedDates,
                    pcontent,
                    pengagement,
                    ppractice,
                    pcareerbenefit
                };
                let previous_3_months_sum = LinechartData.pengagement.slice(-6)[0] + LinechartData.pengagement.slice(-6)[1] + LinechartData.pengagement.slice(-6)[2]
                let last_3_months_sum = LinechartData.pengagement.slice(-6)[3] + LinechartData.pengagement.slice(-6)[4] + LinechartData.pengagement.slice(-6)[5]
                let absoluteDiff = Math.abs(last_3_months_sum - previous_3_months_sum);
                let avg = (last_3_months_sum + previous_3_months_sum) / 2;
                let percentage = (absoluteDiff / avg) * 100;
                let insight_3_result = percentage.toFixed(1);
                let insight_3_grade = false
                if (last_3_months_sum > previous_3_months_sum) {
                    insight_3_grade = true
                } else {
                    insight_3_grade = false
                }
                setInstructorInsights(prevState => ({ ...prevState, insight_3_result, insight_3_grade }))
            });


    }, [])


    return (
        <main class={style.content}>
            <div class="container-fluid p-0 mt-3">
                <h1 class="mb-3">
                    <strong>{instructorName.map((data) => (
                        data.Instructor_Name
                    ))}
                    </strong>
                </h1>
                <div class={`${style.card} flex-fill w-100`}>
                    <div class="card-body d-flex">
                        <div className='col-md-12 col-lg-12 mt-3'>
                            <p class={`${style.text_muted} `}>We have analyzed user opinions related to 4 effectiveness factors including <strong>Content</strong>, <strong>Engagement</strong>, <strong>Quality Practice</strong> and <strong>Career Benefit</strong>.</p>
                            <h5 class={`${style.card_title}`}>Key Insights:</h5>
                            <ul>
                                <li class={`${style.text_muted}`}>Your content got {instructorInsights.insight_1_result >= 0 ? `${instructorInsights.insight_1_result}% more` : ` ${-instructorInsights.insight_1_result}% fewer`} positive reviews than your top competitor.</li>
                                <li class={`${style.text_muted}`}>
                                    Over the last 3 months, the number of positive reviews you've received has
                                    {instructorInsights.insight_3_grade ? ` increased by ${instructorInsights.insight_3_result}% ` : ` decreased by ${instructorInsights.insight_3_result}% `}
                                    compared to the previous period.
                                </li>
                                <li class={`${style.text_muted}`} >Your Practice got {instructorInsights.insight_2_result >= 0 ? `${instructorInsights.insight_2_result}% more` : ` ${-instructorInsights.insight_2_result}% fewer`} positive reviews than your top competitor.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className={`row mt-3 ${memberGroup === 'Basic' ? `${style.blur_effect}`: ''}`}>
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_body} py-4`}>
                                <div class={`${style.card_body} row`}>
                                    {donutTotal.map((data, index) => (
                                        <>
                                            <ReactDonetChart setPage={setPage} setFilter={setFilter} total={data} positive={donutPositive[index]} name={donutName[index]} index={index} />
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={`row mt-3 ${memberGroup === 'Basic' ? `${style.blur_effect}`: ''}`}>
                    <div class="col-12 col-xxl-6 d-flex">
                        <div class={`${style.card} flex-fill w-100`}>
                            <div class={`${style.card_header}`}>
                                <h5 class={`${style.card_title}`}>Analysis By Effectiveness Factors</h5>
                            </div>
                            <div class={`${style.card_body} px-4`}>
                                <p class={`${style.text_muted} mb-2`}>Analyzing Course Effectiveness: Breakdown of Sentiment by Key Factors</p>
                                <ReactStackedBarChart FactorsDetail={instructorFactorsDetail} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}
