import React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    indexAxis: "y" ,
    plugins: {
        legend: {
            labels: {
                color: 'black',
                font: {
                    size: '12',
                }
            }
        },
        datalabels: {
            display: true,
            anchor: 'center',
            align: 'center',
            color: '#fff',
            font: {
                size: 14,
                weight: 'bold'
            },
        }
    },
    scales: {
        x: {
            stacked: true,
            ticks: {
                color: 'black',
            }
        },
        y: {
            stacked: true,
            ticks: {
                color: 'black',
            }
        },
    },

};

const labels = ["Content", "Engagement", "Practice", "Career Benefit"];

const ReactStackedBarChart = ({ FactorsDetail }) => {
    const data = {
        labels,
        datasets: [
            {
                label: 'Positive',
                data: FactorsDetail.positive,
                backgroundColor: '#037ef3',
                borderColor: "black",
                borderWidth: 2,
            },
            {
                label: 'Neutral',
                data: FactorsDetail.neutral,
                backgroundColor: '#ffc845',
                borderColor: "black",
                borderWidth: 2,
                minBarLength: 20,
            },
            {
                label: 'Negative',
                data: FactorsDetail.negative,
                backgroundColor: '#FF0000',
                borderColor: "black",
                borderWidth: 2,
                minBarLength: 20,
            }

        ],
    };
    return  <Bar options={options} data={data} />
        
    
}

export default ReactStackedBarChart
