import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios'
import Pagination from '../Pagination';
import { hashedApiKey } from '../../api';
import style from '../DashboardInstructorContent/InstructorContent.module.css'
import { API_BASE_URL } from '../../api';

const PageLimit = 25;

export default function Dropdown({ memberGroup, setFilter, filter, instructorId, topReviews, topRecommendations, reportCourseID, reportTopReviews }) {
    const [reviewsForReport, setReviewsForReport] = useState([]);
    // courses name list
    const [courseNameList, setCourseNameList] = useState([])
    const [factorList, setFactorList] = useState([])
    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourses', { params: { instructorID: instructorId }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseNameList(response.data.map(record => ({ id: record.Course_ID, name: record.Course_Name })))
        })
    }, [instructorId])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getFactorList', { params: { instructorID: instructorId }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            console.log(response.data)
            setFactorList(response.data)
        })
    }, [instructorId])

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * PageLimit;
    const endIndex = startIndex + PageLimit;
    const currentPageData = reviewsForReport.slice(startIndex, endIndex);

    const handleCoursesChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            course: event.target.value
        }));
    }
    const handleFactorsChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            factor: event.target.value
        }));
    }
    const handleScoreChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            score: event.target.value
        }));
    }

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getReviewsForReport', { params: { instructorID: instructorId, courseID: filter.course, factorName: filter.factor, score: filter.score }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setReviewsForReport(response.data)
        })
    }, [filter])

    return (
        <div className='container mt-3'>
            <div class="form-group row">
                <div className='col-12 col-md-4 mb-3'>
                    <label>Choose Courses: </label>
                    <select name="Courses" class="form-control" onChange={handleCoursesChange} value={filter.course}>
                        <option value='*'>All Courses</option>
                        {courseNameList.map((course) => (
                            <option value={course.id}>{course.name}</option>
                        ))}
                    </select>
                </div>
                {/* <div className='col-12 col-md-4 mb-3'>
                    <label>Effectiveness Factors:</label>
                    <select name="TTC factor" class="form-control" onChange={handleFactorsChange} value={filter.factor} >
                        <option value="*" >Overall Reviews</option>
                        <option value="Is_Content_Review">Content</option>
                        <option value="Is_Engagement_Review">Engagement</option>
                        <option value="Is_Practice_Review">Practice</option>
                        <option value="Is_CareerBenefit_Review">Career benefit</option>
                        <option value="Is_Recommendations_Review">Recommendation</option>
                    </select>
                </div> */}
                <div className='col-12 col-md-4 mb-3'>
                    <label>Effectiveness Factors:</label>
                    <select
                        name="TTC factor"
                        className="form-control"
                        onChange={handleFactorsChange}
                        value={filter.factor}
                    >
                        <option value="*">Overall Reviews</option>
                        {factorList.map(factor => (
                            <option key={factor.Factor_ID} value={factor.Factor_Name}>
                                {factor.Factor_Name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='col-12 col-md-4 mb-3'>
                    <label>Senteez Score:</label>
                    <select name="Score" class="form-control" onChange={handleScoreChange} value={filter.score}>
                        <option value="*">All Scores</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
            </div>
            <div class={`table-responsive ${memberGroup === 'Basic' ? `${style.blur_effect}` : ''}`}>
                <table className="table">
                    <thead className="thead-dark">
                        <tr>
                            {/* <th class="text-center d-none d-sm-none d-md-table-cell">#</th> */}
                            <th class="col-2 col-sm-2 col-md-2 d-sm-table-cell text-center">Review By</th>
                            <th class="col-11 col-sm-10 col-md-8 text-center">Review</th>
                            <th class="d-none d-sm-none col-md-2 d-md-table-cell text-center">Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentPageData.map((data) => (
                                <tr>
                                    {/* <th  scope="row">{data.Course_ID}</th> */}
                                    <td class="col-1 col-sm-1 d-sm-table-cell text-center">{data.Display_Name}</td>
                                    <td class="col-11 col-sm-10 col-md-7">{data.Course_Review}</td>
                                    <td class="d-none d-sm-none d-md-table-cell text-center">{data.Senteez_Score}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <div className="mr-3">
                    <p className="mb-0">Showing {startIndex} to {endIndex} of {reviewsForReport.length} entries</p>
                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        reviewsForReport={reviewsForReport}
                        PageLimit={PageLimit}
                    />
                </div>
            </div>
        </div>
    )
}

