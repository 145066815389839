import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReactHorizontalBarChart = ({ StarCount, setPage, setFilter, courseID, labels }) => {
  const options = {
    indexAxis: 'y', // This makes the bars horizontal
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        offset: 10, // Adjust this value for proper spacing
        color: '#000',
        font: {
          size: 14,
          weight: 'bold'
        },
      }
    },
    // onClick: (event, elements) => {
    //   if (elements.length > 0) {
    //     const index = elements[0].index;
    //     const factor = labels[index]; // Get the Factor_Name corresponding to the clicked bar

    //     // Navigate to the reports page and set the filter
    //     setPage('reports');
    //     setFilter({ course: courseID, factor: factor, score: '*' });
    //   }
    // }
  };

  const data = {
    labels, // Dynamic labels
    datasets: [
      {
        label: 'Total',
        data: StarCount,
        // backgroundColor: '#7552cc', 
        backgroundColor: [
          '#FF0000',
          '#f48924',
          '#ffc845',
          '#7552cc',
          '#037ef3'
        ],
        borderColor: "black",
        borderWidth: 2,
      }
    ],
  };

  return <Bar options={options} data={data} />
}

export default ReactHorizontalBarChart;
