import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom';
import style from './Sidebar.module.css'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

const Sidebar = ({ id, setPage, setCourseID, setFilter, isOpen }) => {
    const [courseNameList, setCourseNameList] = useState([])
    const [instituteName, setInstituteName] = useState([])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getCourses', { params: { instructorID: id }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setCourseNameList(response.data.map(record => ({ id: record.Course_ID, name: record.Course_Name })))
        })
    }, [])

    useEffect(() => {
        Axios.get(API_BASE_URL + '/api/getInstituteName', { params: { instituteID: JSON.parse(localStorage.getItem('institute')) }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
            setInstituteName(response.data)
        })
    }, [])

    return (
        <nav id={`sidebar`} className={`navbar-collapse ${isOpen ? 'show' : 'collapse'} ${style.sidebar}`}>
            <div class={`${style.sidebar_content}`} >
                <a class={style.sidebar_brand} href={'/institute'}>
                    {instituteName.map((data) => (
                        data.Institute_Name
                    ))}
                </a>
                <ul class={style.sidebar_nav}>
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('instructor') }}>My Dashboard</a>
                    </li>
                    {/* <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('OCES') }}>Effectiveness Score</a>
                    </li>
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('reports'); setFilter({ course: '*', factor: 'Is_Recommendations_Review', score: '*' }) }}>Recommandations</a>
                    </li>
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags}`} onClick={() => { setPage('reports'); setFilter({ course: '*', factor: '*', score: '5' }) }}>Top Reviews</a>
                    </li> */}
                    <li class={style.sidebar_header} >
                        <a class={`${style.a_tags} collapsed`} data-toggle="collapse" href="#dashboard" aria-expanded="false">My Courses</a>
                    </li>
                    <div class="collapse" id="dashboard">
                        <ul class="nav nav-collapse">
                            {
                                courseNameList.map(courseName => (
                                    <li class={`${style.sidebar_item} ${style.li}`}>
                                        <Link to={`/dashboard`} onClick={() => { setPage('courses'); setCourseID(courseName.id) }} class={style.sidebar_link}>
                                            <i class="align-middle" data-feather="sliders"></i> <span
                                                class="align-middle">{courseName.name}</span>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </ul>
                <div class={style.copywrite}>
                    <button className='btn btn-sm btn-primary' onClick={() => window.open('https://senteez.com', '_blank')}>&copy; Senteez.com {new Date().getFullYear()}</button>
                    <div>
                        <a class={`${style.a_tagsp}`} href="https://www.linkedin.com/company/senteez/" target="_blank">LinkedIn</a> | <a class={`${style.a_tagsp}`} href="https://www.instagram.com/senteezanalysis/" target="_blank">Instagram</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar;

