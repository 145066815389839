import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    plugins
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    plugins,
    ChartDataLabels
);


export default function ReactCompatitorChart({ id, instructorData, competitorData }) {

    const chartData = {
        labels: ['Overall', 'Content', 'Engagement', 'Practice', 'Career Benefit'],
        datasets: [
            {
                label: instructorData.InstructorName,
                backgroundColor: '#037ef3',
                data: [instructorData.InstructorOverall, instructorData.InstructorContent, instructorData.InstructorEngagement, instructorData.InstructorPractice, instructorData.InstructorCareerBenefit,],
                countData: [instructorData.InstructorOverallTotal, instructorData.InstructorContentTotal, instructorData.InstructorEngagementTotal, instructorData.InstructorPracticeTotal, instructorData.InstructorCareerBenefitTotal],
                datalabels: {
                    display: true,
                    anchor: 'center',
                    align: 'center',
                    color: '#ffff',
                    font: {
                        size: 14,
                        weight: 'bold'
                    },
                    formatter: (value, context) => {
                        const count = context.dataset.countData[context.dataIndex];
                        const countDisplay = count >= 1000 ? `${(count / 1000).toFixed(1)}K` : count;
                        return ` ${value}% \n(${countDisplay})`
                    }
                }
            },
            {
                label: competitorData.CompetitorName,
                backgroundColor: '#ffc845',
                data: [competitorData.CompetitorOverall, competitorData.CompetitorContent, competitorData.CompetitorEngagement, competitorData.CompetitorPractice, competitorData.CompetitorCareerBenefit],
                countData: [competitorData.CompetitorOverallTotal, competitorData.CompetitorContentTotal, competitorData.CompetitorEngagementTotal, competitorData.CompetitorPracticeTotal, competitorData.CompetitorCareerBenefitTotal],
                datalabels: {
                    display: true,
                    anchor: 'center',
                    align: 'center',
                    color: '#000',
                    font: {
                        size: 14,
                        weight: 'bold'
                    },
                    formatter: (value, context) => {
                        const count = context.dataset.countData[context.dataIndex];
                        const countDisplay = count >= 1000 ? `${(count / 1000).toFixed(1)}K` : count;
                        return ` ${value}% \n(${countDisplay})`
                    }
                }
            }
        ]
    };

    const options = {
        scales: {
            x: {
                ticks: {
                    color: 'black',
                }
            },
            y: {
                ticks: {
                    color: 'black',
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'black',
                    font: {
                        size: '12',
                    }
                }
            }
        }
    };

    return <Bar options={options} data={chartData} />;

}
