import React, { useState } from 'react'
import style from './Main.module.css'
import Navbar from '../DashboardNavbar/Navbar'
import InstructorContent from '../DashboardInstructorContent/InstructorContent'
import ReactBarChart from '../ReactBarChart'
import CourseContent from '../DashboardCourseContent/CourseContent'
import DashboardReviewsReport from '../DashboardReviewsReports/DashboardReviewsReport'
import OCES from '../DashboardOCES/OCES'


export default function Main({ memberGroup, id, page, setPage, courseID, setCourseID, topRecommendations,isOpen,setIsOpen, setTopRecommendations, setTopReviews, topReviews, setFilter, filter }) {
  const [instructorId, setInstructorId] = useState("");
  const [reportTopReviews, setReportTopReviews] = useState("");
  const [reportCourseID, setReportCourseID] = useState('');

  return (
    <div className={`${style.main}`}>
      <Navbar setIsOpen={setIsOpen} isOpen={isOpen}/>
      {page === 'instructor' && <InstructorContent memberGroup={memberGroup} setFilter={setFilter} id={id} setPage={setPage} setCourseID={setCourseID} setInstructorId={setInstructorId} setTopReviews={setTopReviews} setTopRecommendations={setTopRecommendations} />}
      {page === 'courses' && <CourseContent memberGroup={memberGroup} setFilter={setFilter} setReportTopReviews={setReportTopReviews} setReportCourseID={setReportCourseID} courseID={courseID} setPage={setPage} setTopRecommendations={setTopRecommendations} />}
      {page === 'reports' && <DashboardReviewsReport memberGroup={memberGroup} filter={filter} setFilter={setFilter} id={id} reportTopReviews={reportTopReviews} reportCourseID={reportCourseID} instructorId={instructorId} topReviews={topReviews} topRecommendations={topRecommendations} />}
      {page === 'OCES' && <OCES memberGroup={memberGroup} setFilter={setFilter} id={id} setPage={setPage} />}
      {/* <InstructorContent id = {id} /> */}
    </div>
  )
}
