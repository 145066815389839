import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../DashboardSideBar/Sidebar'
import style from './Dashboard.module.css'
import Main from '../DashboardMain/Main'
import { hashedApiKey } from '../../api';
import { API_BASE_URL } from '../../api';

export default function Dashboard({page, setPage, filter, setFilter}) {
  // const [page, setPage] = useState('instructor');
  const [courseID, setCourseID] = useState('');
  const [memberGroup, setMemberGroup] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [topReviews, setTopReviews] = useState("");
  const [topRecommendations, setTopRecommendations] = useState('');
  const navigate = useNavigate();
  // const id = JSON.parse(localStorage.getItem('instructor'))
  const [id, setID] = useState(JSON.parse(localStorage.getItem('instructor')))

  const instituteID = JSON.parse(localStorage.getItem('institute'))
  

  useEffect(() => {
    const validateInstructor = async () => {
      try {
        const response = await Axios.get(API_BASE_URL + '/api/validate-instructor', {
          params: { instructorID: id },
          headers: { 'x-api-key': hashedApiKey }
        });

        if (!response.data.isValid) {
          // console.log(response.data)
          localStorage.removeItem('instructor');
          navigate('/institute');

        }
      } catch (error) {
        // Handle error if any
      }
    };

    validateInstructor();
  }, [instituteID]);

  useEffect(() => {
    Axios.get(API_BASE_URL + '/api/getMemberGroup', { params: { instituteID: instituteID }, headers: { 'x-api-key': hashedApiKey } }).then((response) => {
      setMemberGroup(response.data[0].Member_Group_Name)
    })
  }, [])

  return (
    <div className={`${style.wrapper}`}>
      <Sidebar isOpen={isOpen} setFilter={setFilter} setPage={setPage} setTopRecommendations={setTopRecommendations} setCourseID={setCourseID} id={id} setTopReviews={setTopReviews} />
      <Main memberGroup={memberGroup} isOpen={isOpen} setIsOpen={setIsOpen} filter={filter} setFilter={setFilter} id={id} page={page} setPage={setPage} setCourseID={setCourseID} courseID={courseID} topRecommendations={topRecommendations} setTopRecommendations={setTopRecommendations} topReviews={topReviews} setTopReviews={setTopReviews} />
    </div>
  )
}
